export function getRelativeTime(dateString) {
    const now = new Date();
    const past = new Date(dateString);
    const diffInSeconds = Math.floor((now - past) / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInWeeks = Math.floor(diffInDays / 7);
    const diffInMonths = Math.floor(diffInDays / 30.44);
    const diffInYears = Math.floor(diffInDays / 365.25);

    const formatPlural = (value, unit) => 
        `${value} ${unit}${value !== 1 ? 's' : ''} ago`;

    switch (true) {
        case (diffInSeconds < 60):
            return 'just now';
        case (diffInMinutes < 60):
            return formatPlural(diffInMinutes, 'minute');
        case (diffInHours < 24):
            return formatPlural(diffInHours, 'hour');
        case (diffInDays < 7):
            return formatPlural(diffInDays, 'day');
        case (diffInWeeks < 4):
            return formatPlural(diffInWeeks, 'week');
        case (diffInMonths < 6):
            return formatPlural(diffInMonths, 'month');
        case (diffInMonths < 12):
            return diffInMonths < 7 ? 'half a year ago' : formatPlural(diffInMonths, 'month');
        case (diffInYears < 2):
            return 'a year ago';
        case (diffInYears < 100):
            return formatPlural(diffInYears, 'year');
        default:
            return past.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' });
    }
}