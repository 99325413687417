import React, { useEffect, useState } from 'react';
import {GrStatusGood} from "react-icons/gr";

const Success = ({ message, onClose, index }) => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(false);
            onClose();
        }, 5000);

        return () => clearTimeout(timer);
    }, [onClose]);

    if (!isVisible) return null;

    return (
        <div
            className={`z-10 fixed bottom-5 right-5 bg-gray-800 text-white p-4 rounded-lg shadow-lg max-w-sm flex items-center transition-all duration-300 ease-in-out`}
            style={{transform: `translateY(${-index * 70}px)`}}
        >
            <GrStatusGood className={"text-green-600 text-2xl ml-1"}/>
            <span className="text-base mx-2">{message}</span>
        </div>
    );
};

export default Success;