import React, {useState, useEffect, useRef, useCallback} from 'react';
import Warning from "../utils/notify/Warn";
import Success from "../utils/notify/Success";
import Spinner from "../utils/sipnner/Spinner";
import { useNavigate } from 'react-router-dom';
import {API_BASE_URL} from "../../config";
import {useCookies} from "../../cookieContext";
import StructuredData from "../utils/StructuredData";

const OTPVerification = ({userInformation}) => {
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const [timeLeft, setTimeLeft] = useState(120);
    const [canResend, setCanResend] = useState(false);
    const inputRefs = useRef([]);
    const [warnings, setWarnings] = useState([]);
    const [successes, setSuccesses] = useState([]);
    const [nextId, setNextId] = useState(0);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { setCookie } = useCookies();


    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft((prevTime) => {
                if (prevTime <= 1) {
                    clearInterval(timer);
                    setCanResend(true);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [timeLeft]);

    const handleChange = (index, value) => {
        if (isNaN(value)) return;
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (value && index < 5) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (index, e) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    const handleResend = async () => {
        if (timeLeft === 0) {
            try {
                setLoading(true);
                addSuccess("New OTP sent!");
                setTimeLeft(120);
                setOtp(['', '', '', '', '', '']);
                setCanResend(true);
                inputRefs.current[0].focus();

                let userInfo = {
                    email: userInformation.email
                }

                const response = await fetch(API_BASE_URL+'/api/auth/verify-otp', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(userInfo)
                })

                const data = await response.json();

                userInformation.otpCode = data;
            } catch (error) {
                console.error('Error during Google login:', error);
                addWarning("Failed to send OTP. Please try again later.");
            } finally {
                setLoading(false);
            }

        } else {
            addWarning("Cannot resend code. Please wait for the timer to expire.");
        }
    };

    const verifyOTP = () => {
        const otpString = otp.join('');
        if (otpString.length === 6) {
            if (timeLeft > 0) {
                let otpSent = userInformation.otpCode;
                if (otpString.toString().trim() === otpSent.toString().trim()) {
                    setCookie('token', userInformation.token);
                    setCookie('verified', userInformation.verified);
                    setCookie('profileImageUrl', userInformation.profileImageUrl);
                    setCookie('email', userInformation.email);
                    setCookie('username', userInformation.username);
                    navigate('/');

                    window.location.reload();
                    addSuccess("OTP verified successfully");
                }


            } else {
                addWarning("OTP has expired. Please request a new one.");
            }
        } else {
            addWarning("Please enter a 6-digit OTP");
        }
    };

    const addWarning = (message) => {
        setWarnings(prevWarnings => {
            const newWarning = {id: nextId % 5, message};
            let newWarnings;
            if (prevWarnings.length >= 5) {
                // Remove the oldest warning and add the new one
                newWarnings = [...prevWarnings.slice(1), newWarning];
            } else {
                newWarnings = [...prevWarnings, newWarning];
            }
            setNextId(nextId + 1);
            return newWarnings;
        });
    };

    const addSuccess = (message) => {
        setSuccesses(prevSuccess => {
            const newSuccess = {id: nextId % 5, message};
            let newSuccesses;
            if (prevSuccess.length >= 5) {
                // Remove the oldest warning and add the new one
                newSuccesses = [...prevSuccess.slice(1), newSuccess];
            } else {
                newSuccesses = [...prevSuccess, newSuccess];
            }
            setNextId(nextId + 1);
            return newSuccesses;
        });
    };

    const removeWarning = useCallback(() => {
        setWarnings(prevWarnings => prevWarnings.slice(1));
    }, []);

    const structuredData = {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "url": "https://w404.net/login",
        "name": "W404 Login",
        "description": "Login to W404 World using your Google account to create and share amazing things without limits.",
        "publisher": {
            "@type": "Organization",
            "name": "W404",
            "logo": {
                "@type": "ImageObject",
                "url": "https://w404.net/assets/images/logo1.png"
            }
        },
        "potentialAction": {
            "@type": "LoginAction",
            "target": "https://w404.net/login"
        },
        "sameAs": [
            "https://www.facebook.com/w404.net",
        ]
    };

    return (
        <div className={"container max-h-screen bg-[#121212] overflow-hidden"}>
            <StructuredData data={structuredData} title={"W404 Login"} url={"https://w404.net/login"}
                            description={"Login to W404 World using your Google account to create and share amazing things without limits."}/>
            {warnings.map((warning, index) => (
                <Warning
                    key={warning.id}
                    message={warning.message}
                    onClose={removeWarning}
                    index={index}
                />
            ))}
            {successes.map((success, index) => (
                <Success
                    key={success.id}
                    message={success.message}
                    onClose={removeWarning}
                    index={index}
                />
            ))}
            {loading ? ( <Spinner/> ) : (

            <div
                className="-mt-10 flex justify-center items-center bg-opacity-90 min-h-screen">
                <div
                    className="opacity-95 bg-gray-800 bg-opacity-80 p-12 rounded-2xl shadow-lg backdrop-blur-md border border-white border-opacity-10 max-w-md w-full text-center">
                    <h1 className="text-3xl font-semibold text-white mb-6">OTP Verification</h1>
                    <p className="text-gray-300 mb-8">
                        Enter the OTP you received to <span
                        className="text-purple-400 font-medium">{userInformation.email}</span>
                    </p>
                    <div className="flex justify-center mb-8">
                        {otp.map((digit, index) => (
                            <input
                                key={index}
                                ref={(el) => (inputRefs.current[index] = el)}
                                className="w-12 h-12 mx-2 text-center text-xl border-2 border-indigo-500 rounded-xl bg-gray-700 bg-opacity-80 text-white focus:border-purple-400 focus:outline-none focus:ring-2 focus:ring-purple-400 focus:ring-opacity-30 transition-all"
                                type="text"
                                maxLength={1}
                                value={digit}
                                onChange={(e) => handleChange(index, e.target.value)}
                                onKeyDown={(e) => handleKeyDown(index, e)}
                            />
                        ))}
                    </div>
                    <button
                        onClick={verifyOTP}
                        className="bg-gradient-to-r from-indigo-500 to-purple-500 text-white px-6 py-3 rounded-lg font-medium tracking-wide transition-all hover:from-purple-500 hover:to-indigo-500 transform hover:-translate-y-0.5 hover:shadow-lg border-2 border-indigo-500"
                    >
                        Verify
                    </button>
                    <div className="mt-4 text-sm text-gray-400">
                        Didn't receive the code?{' '}

                        <span
                            onClick={handleResend}
                            className={`text-indigo-500 cursor-pointer hover:text-purple-400`}>
                                Resend Code
                            </span>

                        <span className="ml-1 text-purple-400">
                          {timeLeft > 0 ? `(${Math.floor(timeLeft / 60)}:${(timeLeft % 60).toString().padStart(2, '0')})` : 'Code expired'}
                        </span>
                    </div>
                </div>
            </div>
            )}
        </div>
    );
};

export default OTPVerification;