import React from 'react'
import {useCookies} from "../../cookieContext";
import ImageComponent from "./ImageComponent";

function AddComment({onclick, tokenLocal, onChange, className, value}) {
    const {cookies} = useCookies();
    return (
        <div className={`flex items-center w-full ${className}`}>
            {
                tokenLocal != null && window.innerWidth > 720 && (
                    <ImageComponent image={cookies.profileImageUrl} className="mr-2 w-12 h-10 rounded-full border" alt={cookies.username}/>

                )
            }
            <input onChange={onChange} value={value} type="text" placeholder="Add a comment..."
                   className="bg-gray-700 w-full p-2 rounded-full mr-2 px-4"/>
            <button onClick={onclick}
                    className="relative overflow-hidden text-[#F4F4F5] font-bold py-2 px-6 rounded-full border-2 border-[#6665F1] transition-all duration-300 hover:shadow-lg hover:shadow-[#A556F6]/50 active:scale-95"
            >
                <span className="relative z-10">Send</span>
                <span
                    className="absolute inset-0 bg-gradient-to-r from-[#6665F1] to-[#A556F6] opacity-0 hover:opacity-100 transition-opacity duration-300"
                ></span>
            </button>
        </div>
    )
}

export default AddComment