import React, { useState } from 'react';
import { PlaceholdersAndVanishInput } from "../../../ui/placeholders-and-vanish-input";

function SearchPlayGround({searchValue}) {
    const [inputValue, setInputValue] = useState('');

    const placeholders = [
        "Chat GPT-4o",
        "Terminal",
        "Chat AI",
        "Code",
        "Claude AI",
        "Local AI",
        "Free"
    ];

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        searchValue(inputValue);
    };

    return (
        <div className="flex flex-col justify-center items-center px-8 mb-4 mt-2 ">
            <PlaceholdersAndVanishInput
                placeholders={placeholders}
                onSubmit={onSubmit}
                value={inputValue}
                onChange={handleInputChange}
            />
        </div>
    )
}

export default SearchPlayGround;