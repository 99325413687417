import React from 'react';
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";

export function CircularPagination({ page, setPage, totalPages }) {
    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxVisiblePages = 5;

        let startPage = Math.max(1, page - Math.floor(maxVisiblePages / 2));
        let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

        if (endPage - startPage + 1 < maxVisiblePages) {
            startPage = Math.max(1, endPage - maxVisiblePages + 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <button
                    key={i}
                    onClick={() => handlePageChange(i)}
                    className={`rounded-full w-10 h-10 flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 ${
                        page === i
                            ? 'bg-gray-800 text-white hover:bg-gray-700'
                            : 'text-gray-600 bg-white border border-gray-300 hover:bg-gray-100'
                    }`}
                >
                    {i}
                </button>
            );
        }

        return pageNumbers;
    };

    return (
        <div className="flex items-center gap-4">
            <button
                onClick={() => handlePageChange(Math.max(1, page - 1))}
                disabled={page === 1}
                className="px-3 py-1 rounded bg-gray-200 text-gray-600 disabled:opacity-50"
            >
                <FaArrowLeftLong/>
            </button>
            <div className="flex items-center gap-2">
                {renderPageNumbers()}
            </div>
            <button
                onClick={() => handlePageChange(Math.min(totalPages, page + 1))}
                disabled={page === totalPages}
                className="px-3 py-1 rounded bg-gray-200 text-gray-600 disabled:opacity-50"
            >
                <FaArrowRightLong/>
            </button>
        </div>
    );
}