import React, {useState, useEffect, Fragment, useCallback} from 'react';
import {Button, Dialog} from '@headlessui/react';
import {
    FaArrowRight,
    FaArrowLeft,
    FaPaperPlane,
    FaFileAlt,
    FaTag,
    FaAlignLeft,
    FaImage,
    FaTimes,
    FaUpload
} from 'react-icons/fa';
import RichTextEditor from "../../../utils/RichTextEditor";
import MenuComponent from "../../../utils/menu/MenuComponent";
import CategorySelect from "../../../utils/CategorySelect";
import {FaCheck} from "react-icons/fa6";
import {API_BASE_URL} from "../../../../config";
import Spinner from "../../../utils/sipnner/Spinner";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import CodeEditor from "../advanced/CodeEditor";
import {useCookies} from "../../../../cookieContext";
import ConfirmationDialog from "../../../utils/ConfirmationDialog";
import StructuredData from "../../../utils/StructuredData";

const BlogPostForm = () => {
    const editorType = useLocation().state?.editorType || 'markdown';
    const {slug, username} = useParams();
    const [content, setContent] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [editLink, setEditLink] = useState('');
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [shortDescription, setShortDescription] = useState('');
    const [image, setImage] = useState(null);
    const [step, setStep] = useState(1);
    const [beamProgress, setBeamProgress] = useState(0);
    const [isComment, setComment] = useState(true);
    const [isPublic, setPublic] = useState(true);
    const [imageUrl, setImageUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [oldBlog, setOldBlog] = useState(null);
    const [slugAvailable, setSlugAvailable] = useState([]);
    const [imageUpload, setImageUpload] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)
    const [imageError, setImageError] = useState(false);

    const navigate = useNavigate();
    const {cookies} = useCookies();

    useEffect(() => {
        if (cookies.token === null && cookies.username === null && cookies.username !== username) {
            navigate('/login');
        }
    })

    const handleDeleteBlog = async () => {
        try {
            await fetch(`${API_BASE_URL}/api/v1/blog/edit/${oldBlog.id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + cookies.token
                },
                body: JSON.stringify({
                    token: cookies.token
                })
            });
            setIsDeleteOpen(false);
        } catch (error) {
            setIsDeleteOpen(false);
            console.error('Error:', error);
        } finally {
            navigate(`/blog/types`);
            setIsDeleteOpen(false);
        }
    }

    const checkSlugAvailability = useCallback(async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/api/v1/blog/get/slugs/${username}`)
            if (!response.ok) {
               throw new Error('Failed to check slug availability');
            }
            const data = await response.json();
            setSlugAvailable(data)

        } catch (error) {
            console.error('Error checking slug availability:', error);
            setSlugAvailable([]);
        }
    },[username]);

    const fetchCategories = useCallback(async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/api/v1/blog-tag/get/link/${slug}`);
            const data = await response.json();
            const tagNames = data.map(item => item.tagName);
            setSelectedCategories(tagNames);
        } catch (error) {
            console.error('Error:', error);
        }
    }, [slug]);

    const fetchBlog = useCallback(async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/api/v1/blog/get/${username}/${slug}`);
            const data = await response.json();
            setContent(data.body);
            setTitle(data.title);
            setEditLink(data.slug);
            setShortDescription(data.shortDescription);
            setImage(data.imageUrl);
            setImageUrl(data.imageUrl);
            setComment(data.comment);
            setPublic(data.status === "PUBLISH");

            setOldBlog(data);
        } catch (error) {
            navigate('/404');
            console.error('Error:', error);
        }
    }, [username, slug]);

    useEffect(() => {
    }, [content, selectedCategories, slugAvailable]);

    useEffect(() => {
        if (slug && username) {
            const fetchData = async () => {
                await fetchBlog();
                await fetchCategories();
                await checkSlugAvailability();
            };
            void fetchData();
        }
    }, [slug, username, fetchBlog, fetchCategories, checkSlugAvailability]);

    useEffect(() => {
        if (isDialogOpen) {
            setBeamProgress(step === 1 ? 25 : step === 2 ? 50 : step === 3 ? 75 : step === 4 ? 90 : 98);
        } else {
            setBeamProgress(0);
        }
    }, [isDialogOpen, step]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsDialogOpen(true);
    };

    const handleNext = () => {
        if (step < 5) {
            setStep(step + 1);
        } else {
            void handleFinalSubmit();
        }
    };

    const handleBack = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    };

    const handleFinalSubmit = async () => {
        try {
            setLoading(true);
            let blog;
            let data;
            if (oldBlog !== null) {
                blog = {
                    id: oldBlog.id,
                    title: title,
                    slug: editLink,
                    content: content,
                    shortDescription: shortDescription,
                    imageUrl: imageUrl,
                    publicPost: isPublic,
                    commentAllowed: isComment,
                    token: cookies.token
                }

                const response = await fetch(API_BASE_URL + `/api/v1/blog/edit/${blog.id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + cookies.token
                    },
                    body: JSON.stringify(blog)
                });

                if (!response.ok) {
                    console.error(`HTTP error! status: ${response.status}`);
                }
                data = await response.json();
            } else {
                blog = {
                    title: title,
                    slug: editLink,
                    content: content,
                    shortDescription: shortDescription,
                    imageUrl: imageUrl,
                    publicPost: isPublic,
                    commentAllowed: isComment,
                    simple: editorType === 'markdown',
                    token: cookies.token
                }

                const response = await fetch(API_BASE_URL + '/api/v1/blog/edit/add', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + cookies.token
                    },
                    body: JSON.stringify(blog)
                });
                if (!response.ok) {
                    console.error(`HTTP error! status: ${response.status}`);
                }

                data = await response.json();

            }

            for (const selectedCategory of selectedCategories) {
                await fetch(API_BASE_URL + '/api/v1/blog-tag/edit/add', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + cookies.token
                    },
                    body: JSON.stringify({
                        blogId: data.id,
                        tagName: selectedCategory
                    })
                });
            }
            clearForm();
        } catch (error) {
            console.error('Error:', error);
        } finally {
            navigate(`/blogs`);
            setLoading(false);
        }
    };

    const clearForm = () => {
        setIsDialogOpen(false);
        setStep(1);
        setContent('');
        setTitle('');
        setEditLink('');
        setSelectedCategories([]);
        setShortDescription('');
        setImage(null);
        setComment(true);
        setPublic(true);
        setImageUrl(null);
    }

    const handleImageUpload = (e) => {
        if (image !== null) {
            handleImageDelete();
        }

        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('image', file);
            formData.append('email', cookies.email);

            fetch(`${API_BASE_URL}/api/v1/image/upload`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + cookies.token
                },
                body: formData
            })
                .then(response => response.json())
                .then(data => {
                    setImageUrl(API_BASE_URL + data.url); // Prepend API_BASE_URL to the relative URL
                    setImage(URL.createObjectURL(file));
                    setImageUpload(true);
                })
                .catch(error => setImageError(true));
        }
    };

    const handleImageDelete = () => {
        if (imageUrl) {
            const filename = imageUrl.split('/').pop(); // Get the filename from the URL
            const email = cookies.email;

            fetch(`${API_BASE_URL}/api/v1/image/delete?email=${encodeURIComponent(email)}&filename=${encodeURIComponent(filename)}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + cookies.token
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to delete image');
                    }
                    return response.json();
                })
                .then(data => {
                    setImageUrl(null);
                    setImage(null);
                    const fileInput = document.getElementById('imageUpload');
                    if (fileInput) fileInput.value = '';

                    if (oldBlog.imageUrl !== null && imageUpload === false) {
                        void handleDeleteImageBlog();
                    }

                })
                .catch(error => console.error('Error:', error));
        }
    };

    const handleDeleteImageBlog = async () => {
        try {
            await fetch(`${API_BASE_URL}/api/v1/blog/edit/image/${oldBlog.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + cookies.token
                }
            })
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const renderStepContent = () => {
        switch (step) {
            case 1:
                return (
                    <div className="space-y-4">
                        <input
                            type="text"
                            placeholder="Enter your blog post title"
                            max={201}
                            className="w-full p-2 bg-gray-800 text-white border border-gray-700 rounded-md transition-all duration-300 focus:ring-2 focus:ring-[#6665F1] focus:outline-none"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                        {
                            title.length < 6 && (
                                <span className="text-xs text-red-600">
                            Please enter title
                        </span>
                            )
                        }
                        <div className="space-y-2">
                            <label htmlFor="editLink" className="block text-sm font-medium text-gray-300">
                                Edit link (optional)
                            </label>
                            <input
                                id="editLink"
                                type="text"
                                placeholder="Enter a unique link for your blog post"
                                max={201}
                                className="w-full p-2 bg-gray-800 text-white border border-gray-700 rounded-md transition-all duration-300 focus:ring-2 focus:ring-[#6665F1] focus:outline-none"
                                value={editLink}
                                onChange={(e) => {
                                    const value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
                                    setEditLink(value);
                                }}
                            />
                            {
                                editLink.length > 5 ? (
                                    <span className="text-xs text-gray-400">
                                      {slugAvailable.length === 0 ? (
                                          ''
                                      ) : slugAvailable.find(link => link.toLowerCase() === editLink.toLowerCase())
                                      && ( !oldBlog || oldBlog.slug !== editLink) ? (
                                          <span className={"text-red-400"}>
                                              Sorry, this link is already used for{' '}
                                              <Link className={"underline"}
                                                    to={`/${username}/s/${editLink}`}>this blog</Link>.
                                          </span>
                                      ) : (
                                          <span className={"text-green-400"}>This link is available for use.</span>
                                      )}
                                    </span>
                                ) : (
                                    <span className="text-xs text-red-600">
                                      Please enter a link longer than 5 characters for better uniqueness.
                                    </span>
                                )
                            }
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className="space-y-4 py-5">
                        <CategorySelect
                            blogId={oldBlog !== null ? oldBlog.id : null}
                            selectedCategories={selectedCategories}
                            setSelectedCategories={setSelectedCategories}
                        />
                    </div>
                );
            case 3:
                return (
                    <div className="space-y-2">
                        <label htmlFor="shortDescription" className="block text-sm font-medium text-gray-300">
                            Short Description (optional)
                        </label>
                        <textarea
                            id="shortDescription"
                            placeholder="Enter a brief description of your blog post"
                            rows={3}
                            className="w-full p-2 bg-gray-800 text-white border border-gray-700 rounded-md transition-all duration-300 focus:ring-2 focus:ring-[#6665F1] focus:outline-none resize-none"
                            value={shortDescription}
                            onChange={(e) => setShortDescription(e.target.value)}
                        />
                        <p className="text-xs text-gray-400">This will be shown under the blog title.</p>
                    </div>
                );
            case 4:
                return (
                    <div className="space-y-4">
                        <label htmlFor="imageUpload" className="block text-sm font-medium text-gray-300">
                            Upload Image (optional)
                        </label>
                        <div className="flex items-center justify-center w-full">
                            <label htmlFor="imageUpload"
                                   className="flex flex-col items-center justify-center w-full h-64 border-2 border-[#6665F1] border-dashed rounded-lg cursor-pointer bg-gray-800 hover:bg-gray-700 transition-all duration-300">
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                    <FaUpload className="w-10 h-10 mb-3 text-[#e3eeff]"/>
                                    <p className="mb-2 text-sm text-gray-400"><span className="font-semibold">Click to upload</span> or
                                        drag and drop</p>
                                    <p className="text-xs text-gray-400">SVG, PNG, JPG or GIF (MAX. 1MB)</p>
                                </div>
                                <input
                                    id="imageUpload"
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageUpload}
                                    className="hidden"
                                />
                            </label>
                        </div>
                        {
                            imageError ? (
                                <div>
                                    <span className="text-xs text-red-400">
                                        Image too large! Please make sure the image is less than 1MB.
                                    </span>
                                    <span>
                                        or using this
                                        <Link to="https://imageresizer.com/" target="_blank">Image Resizer</Link>
                                    </span>
                                </div>
                            ) : <p className="text-xs text-gray-400">This image will be shown under the blog item.</p>
                        }
                        {image && (
                            <div className={"flex justify-center items-center"}>
                                <div className="relative inline-block">
                                    <img src={image} alt="Uploaded preview" className="max-w-[200px] mt-2 rounded-md"/>
                                    <button
                                        onClick={handleImageDelete}
                                        className="absolute top-2 right-2 bg-red-500 rounded-full p-1 hover:bg-red-600 transition-colors duration-200"
                                    >
                                        <FaTimes size={16}/>
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                );
            case 5:
                return (
                    <div className="space-y-4 my-10">
                        <div className="flex items-center">
                            <span className="text-white ml-6 mr-3">Comment:</span>
                            <label className="relative inline-flex items-center cursor-pointer">
                                <input
                                    className="sr-only peer"
                                    type="checkbox"
                                    checked={isComment}
                                    onChange={(e) => setComment(e.target.checked)}
                                />
                                <div
                                    className="peer rounded-2xl outline-none duration-100 after:duration-500 w-[80px] h-7 bg-[#363636] peer-focus:outline-none after:content-['N'] after:absolute after:outline-none after:h-5 after:w-8 after:bg-white after:top-1 after:left-1 after:flex after:justify-center after:items-center  after:text-sky-800 after:font-bold peer-checked:after:translate-x-10 peer-checked:after:content-['Y'] peer-checked:after:border-white after:rounded-2xl after:px-2">
                                </div>
                            </label>
                        </div>
                        <span
                            className="text-sm text-[#A1A1AA] ml-6 mr-3 m b-3">Turn comments on or off for your blog.</span>

                        <div className="flex items-center">
                            <span className="text-white ml-6 mr-9">Public:</span>
                            <label className="relative inline-flex items-center cursor-pointer">
                                <input
                                    className="sr-only peer"
                                    type="checkbox"
                                    checked={isPublic}
                                    onChange={(e) => setPublic(e.target.checked)}
                                />
                                <div
                                    className="peer rounded-2xl outline-none duration-100 after:duration-500 w-[80px] h-7 bg-[#363636] peer-focus:outline-none after:content-['N'] after:absolute after:outline-none after:h-5 after:w-8 after:bg-white after:top-1 after:left-1 after:flex after:justify-center after:items-center  after:text-sky-800 after:font-bold peer-checked:after:translate-x-10 peer-checked:after:content-['Y'] peer-checked:after:border-white after:rounded-2xl after:px-2">
                                </div>
                            </label>
                        </div>
                        <span
                            className={`text-sm text-[#A1A1AA] ml-6 mr-3 mb-3 ${isPublic ? "hidden" : ""}`}
                        >
                          Only people who have the link to this blog can see it.
                        </span>
                        <span
                            className={`text-sm text-[#A1A1AA] ml-6 mr-3 mb-3 ${!isPublic ? "hidden" : ""}`}
                        >
                          Everyone can see this blog.
                        </span>

                    </div>
                );
            default:
                return null;
        }
    };

    const blogPostStructuredData = {
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        "name": "Create your new blog",
        "description": "Create your new blog",
        "author": {
            "@type": "Person",
            "name": cookies.username
        },
        "publisher": {
            "@type": "Organization",
            "name": "W404",
            "logo": {
                "@type": "ImageObject",
                "url": "https://w404.net/assets/images/logo1.png"
            }
        }
    };

    return (
        <div
            className="min-h-screen bg-gradient-to-br from-[#121212] to-[#1D1D1D] text-white relative overflow-hidden">
            <StructuredData data={blogPostStructuredData} title={oldBlog !== null ? `Edit: ${oldBlog.title}` : "Create your new blog"} url={"https://w404.net/blog/new"}
                            description={"Create your new blog"}/>
            <style>{`
                @keyframes fadeIn {
                  from { opacity: 0; transform: translateY(-10px); }
                  to { opacity: 1; transform: translateY(0); }
                }
                @keyframes shimmer {
                  0% { background-position: -1000px 0; }
                  100% { background-position: 1000px 0; }
                }
                .animate-fadeIn {
                  animation: fadeIn 0.2s ease-out;
                }
                .beam-gradient {
                  background: linear-gradient(90deg, #6665F1, #A556F6);
                  background-size: 200% 200%;
                  animation: shimmer 2s infinite linear;
                }
                .glass-effect {
                  background: rgba(255, 255, 255, 0.05);
                  backdrop-filter: blur(10px);
                  border: 1px solid rgba(255, 255, 255, 0.1);
                }
              `}</style>
            <div className="absolute bottom-0 left-0 w-full h-1 bg-gray-800">
                <div
                    className="h-full beam-gradient transition-all duration-500 ease-in-out"
                    style={{width: `${beamProgress}%`}}
                />
            </div>
            <Fragment>
                <MenuComponent/>
            <div className="container">
                {loading ? <Spinner/> : (
                    <div className={"px-1"}>
                        <div className="flex justify-between mt-6 mb-3">
                            {
                                oldBlog !== null ? (
                                    <Button onClick={() => setIsDeleteOpen(true)}
                                            className="w-fit inline-flex items-center gap-2 rounded-md border border-red-800 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner focus:outline-none data-[hover]:border-red-500 data-[open]:border-red-500 data-[focus]:outline-1 data-[focus]:outline-white transform duration-300">
                                        Delete Blog
                                    </Button>
                                ) : (
                                    <h1 className="code-editor-header-title">Create your new blog</h1>
                                )
                            }
                            <div className="code-editor-button-save">
                                <Button onClick={handleSubmit}
                                        className="w-fit inline-flex items-center gap-2 rounded-md bg-[#6665F195] py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white transform duration-300">
                                    Save changes
                                </Button>
                            </div>
                        </div>

                        {editorType === 'code' || oldBlog?.simple === false ? (
                            <CodeEditor code={content} setCode={setContent} />
                        ) : (
                            <RichTextEditor content={content} setContent={setContent} />
                        )}

                        <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} className="relative z-50">
                            <div className="fixed inset-0 bg-black/30 p-6" aria-hidden="true"/>
                            <div className="fixed inset-0 flex items-center justify-center p-4">
                                <Dialog.Panel
                                    className="w-full max-w-xl transform rounded-2xl bg-gradient-to-br from-[#1A1A1A] to-[#2A2A2A] p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title className="flex items-center text-[#e3eeff] text-2xl font-bold mb-4">
                                        {step === 1 ? <FaFileAlt className="mr-3"/> :
                                            step === 2 ? <FaTag className="mr-3"/> :
                                                step === 3 ? <FaAlignLeft className="mr-3"/> :
                                                    step === 4 ? <FaImage className="mr-3"/> :
                                                        <FaCheck className={"mr-3"}/>}
                                        {step === 1 ? "Title and edit Link" :
                                            step === 2 ? "Select Tags" :
                                                step === 3 ? "Short Description" :
                                                    step === 4 ? "Upload Image" : "Final Check"}
                                    </Dialog.Title>
                                    <div className="my-4">
                                        {renderStepContent()}
                                    </div>
                                    <div className="flex justify-between items-center mt-6">
                                        <div className="w-full flex justify-between">
                                            {step > 1 && (
                                                <button onClick={handleBack}
                                                        className="bg-gray-600 hover:bg-gray-700 text-white transition-all duration-300 transform hover:scale-105 rounded-md py-2 px-4">
                                                    <FaArrowLeft className="mr-2 inline" size={18}/> Back
                                                </button>
                                            )}
                                            <button
                                                onClick={handleNext}
                                                className="bg-gradient-to-r from-[#6665F1] to-[#A556F6] hover:from-[#A556F6] hover:to-[#6665F1] text-white transition-all duration-300 transform hover:  scale-105 rounded-md py-2 px-4"
                                                disabled={(step === 1 && title.length <= 5) || (step === 2 && selectedCategories.length === 0)}
                                            >
                                                {step === 5 ? (
                                                    <>Submit <FaPaperPlane className="ml-2 inline" size={18}/></>
                                                ) : (
                                                    <>Next <FaArrowRight className="ml-2 inline" size={18}/></>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </div>
                        </Dialog>

                        {/*Delete Blog*/}
                        <ConfirmationDialog
                            isOpen={isDeleteOpen}
                            onClose={() => setIsDeleteOpen(false)}
                            onConfirm={handleDeleteBlog}
                            title="Confirm Delete"
                            message="Are you sure you want to delete this blog? This action cannot be undone."
                        />
                    </div>
                )}
            </div>
            </Fragment>
        </div>
    );
};

export default BlogPostForm;
