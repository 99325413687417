import React, {Component} from 'react';
import './sipnner.css';

class Spinner extends Component {
    render() {
        return (
            <div className={"w-full"}>
                <div className="loader m-auto">
                    <span className="loader-text">Loading...</span>
                </div>
            </div>
        );
    }
}

export default Spinner;