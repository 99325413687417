import React, {Component} from 'react';
import AnimatedShinyText from "../../../ui/animated-shiny-text";
import {FaArrowRightLong} from "react-icons/fa6";
import {cn} from "../../../lib/utils";
import {Link} from "react-router-dom";


class HeaderBlogsContentCenter extends Component {
    render() {
        return (
            <div className="z-10 flex items-center justify-center">
                <Link to={"/blog/types"}
                    className={cn(
                        "w-4/5 py-1 text-center group rounded-full border text-base text-white transition-all ease-in hover:cursor-pointer  border-white/5 bg-neutral-900 hover:bg-neutral-800",
                    )}
                >
                    <AnimatedShinyText
                        className="inline-flex items-center justify-center px-4 py-1 transition ease-out hover:duration-300 hover:text-neutral-400">
                        <span>✨ What's on your mind?</span>
                        <FaArrowRightLong
                            className="ml-1 size-3 transition-transform duration-300 ease-in-out group-hover:translate-x-0.5"/>
                    </AnimatedShinyText>
                </Link>
            </div>
        );
    }
}

export default HeaderBlogsContentCenter;