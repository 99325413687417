import React, {useEffect, useState} from 'react';
import MenuComponent from "../utils/menu/MenuComponent";
import { API_BASE_URL } from "../../config";
import {useNavigate, useParams} from 'react-router-dom';
import {Input} from "@headlessui/react";
import bcrypt from "bcryptjs";

function ChangePasswordComponent() {
    const {codeVerify} = useParams();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (!codeVerify) {
            navigate('/');
        }
    }, [codeVerify, navigate]);

    const handleChangePassword = async () => {
        if (newPassword !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        try {
            const salt = await bcrypt.genSalt(10);
            const hashedPassword = await bcrypt.hash(newPassword, salt);

            const response = await fetch(`${API_BASE_URL}/api/auth/update-password`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    codeVerify: codeVerify,
                    password: hashedPassword
                })
            });

            if (!response.ok) {
                setError('The link is invalid or expired, please request a new one');
                return;
            }

            alert('Password changed successfully');

            navigate('/login');
        } catch (e) {
            setError(e.message);
        }
    };

    return (
        <div>
            <MenuComponent/>
            <div className="min-h-[80svh] flex items-center justify-center bg-[#121212] p-4">
                <div className="max-w-md w-full space-y-8 bg-[#1E1E1E] p-8 rounded-xl shadow-lg">
                    <div className="text-center">
                        <h2 className="mt-6 text-3xl font-extrabold text-white">
                            Change Your Password
                        </h2>
                        <p className="mt-4 -mb-3 text-sm text-gray-400">
                            Please enter your new password and confirm it.
                        </p>
                    </div>
                    <div className="mt-8 space-y-6">
                        {error && <p className="text-red-500 text-sm">{error}</p>}
                        <Input
                            type="password"
                            placeholder="New Password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className={
                                'mt-6 py-2 block w-full rounded-lg border-none bg-white/5 px-3 text-sm/6 text-white ' +
                                'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                            }                        />
                        <Input
                            type="password"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className={
                                'mt-6 py-2 block w-full rounded-lg border-none bg-white/5 px-3 text-sm/6 text-white ' +
                                'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                            }
                        />

                        <button
                            onClick={handleChangePassword}
                            className={"my-6 w-full py-2 border rounded-xl text-white hover:bg-white hover:text-black transform duration-300 ease-in-out hover:shadow"}
                            disabled={newPassword !== confirmPassword || newPassword.length <= 6}
                        >
                            Change Password
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChangePasswordComponent;