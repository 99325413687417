import {cn} from "../../../lib/utils";
import "./ButtonBorderAnimationStyle.css";
import {Link} from "react-router-dom";
import {Fragment} from "react";

export const ButtonBorderAnimation = ({children, containerClassName, link}) => {
    return (
        <Fragment>
            <Link to={link} className={cn("button-border-animation float-left justify-center w-[130px] rounded",containerClassName)}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                {children}
            </Link>
        </Fragment>
    )
}