import { Helmet } from 'react-helmet';

const StructuredData = ({ data, url, title, description}) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={truncateDescription(description)}/>

            {/*// <!-- Open Graph / Facebook -->*/}
            <meta property="og:type" content="website"/>
            <meta property="og:url" content={url}/>
            <meta property="og:title" content={title}/>
            <meta property="og:description" content={truncateDescription(description)}/>
            <meta property="og:image" content="https://w404.net/assets/images/logo1.png"/>

            {/*// <!-- Twitter -->*/}
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:url" content={url}/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={truncateDescription(description)}/>
            <meta name="twitter:image" content="https://w404.net/assets/images/logo1.png"/>

            {/*Structured data*/}
            <script type="application/ld+json">
                {JSON.stringify(data)}
            </script>
        </Helmet>
    );
};

export default StructuredData;

export function truncateDescription(description) {
    if (description?.length <= 180) return description;
    return description?.substr(0, 180 - 3) + '...';
}