import React, {useEffect} from 'react'
import {Link, useLocation} from "react-router-dom";
import './MenuStyle.css'
import {BsList} from "react-icons/bs";
import {LiaTimesSolid} from "react-icons/lia";
import {ButtonBorderAnimation} from "../button/ButtonBorderAnimation";
import MenuUserDropdown from "./MenuUserDropdown";
import {useCookies} from "../../../cookieContext";
import {API_BASE_URL} from "../../../config";

function MenuComponent() {
    const [menuVisible, setMenuVisible] = React.useState(false)
    const [loginVisible, setLoginVisible] = React.useState(false)
    const {cookies, setCookie} = useCookies()
    const toggleMenu = () => {
        setMenuVisible(!menuVisible)
    }
    const [isSending, setIsSending] = React.useState(false)

    const [cooldown, setCooldown] = React.useState(0);

    useEffect(() => {
        let timer;
        if (cooldown > 0) {
            timer = setInterval(() => setCooldown(c => c - 1), 1000);
        }
        return () => clearInterval(timer);
    }, [cooldown]);

    const handleVerifyClick = async () => {
        await handleVerify();
        setCooldown(60);
    };

    useEffect(() => {
        const token = cookies.token
        if (token) {
            setLoginVisible(true)
        }
    }, [])

    const location = useLocation()

    const menuItems = [
        {
            title: "About",
            url: "/",
            cName: "nav-links",
            current: true
        },
        {
            title: "Explore",
            url: "/explore",
            cName: "nav-links",
            current: false
        },
        {
            title: "Blogs",
            url: "/blogs",
            cName: "nav-links",
            current: false
        }
    ]

    const handleVerify = async () => {
    try {
        setIsSending(true);
        const response = await fetch(`${API_BASE_URL}/api/auth/verify-email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${cookies.token}`
            },
            body: JSON.stringify({
                username: cookies.username,
                email: cookies.email,
            })
        });
        
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.text();
        setCookie('codeVerify', data, {expires: 1});
        setLoginVisible(true);
    } catch (e) {
        console.error('Error verifying email:', e);
    } finally {
        setIsSending(false);
    }
}

    return (
        <div className={"menu-component"}>
            <nav className={"NavbarItems z-[100]"}>
                <div className="menu-icon text-[#F4F4F5]" onClick={toggleMenu}>
                    {
                        menuVisible ? <LiaTimesSolid/> : <BsList/>
                    }
                </div>
                <Link to={"/"} className="navbar-logo">
                    <img
                        className="navbar-logo-img"
                        src={window.innerWidth > 800 ? "/assets/images/logo2.png" : "/assets/images/logo1.png"}
                        alt="Logo"
                    />
                </Link>
                <ul className={menuVisible ? 'nav-menu active' : 'nav-menu'}>
                    {
                        menuItems.map((item, index) => {
                            const isActive = item.url === '/'
                                ? location.pathname === '/'
                                : location.pathname.startsWith(item.url) ||
                                (item.url === '/explore' && location.pathname.includes('/e/')) ||
                                (item.url === '/blogs' && location.pathname.includes('/b/'));
                            return (
                                <li key={index}>
                                    <Link to={item.url}
                                          className={`${item.cName} ${isActive ? 'isActive' : ''}`}>{item.title}</Link>
                                </li>
                            )
                        })
                    }
                </ul>
                {
                    loginVisible ? (
                        <div className={"nav-btn"}>
                            <MenuUserDropdown/>
                        </div>
                    ) : (
                        <div className={"nav-btn"}>
                            <ButtonBorderAnimation link={"/login"} containerClassName={"uppercase"}>Sign
                                In</ButtonBorderAnimation>
                        </div>
                    )
                }

            </nav>
            {
                cookies?.verified === 'false' && (
                    <div
                        className="border-b border-red-300/5 p-2 shadow-md border-t">
                        <div className="container flex items-center  justify-between">
                            <span
                                className="text-white font-medium">Please verify your email address to access all features.</span>
                            <button
                                onClick={handleVerifyClick}
                                disabled={isSending || cooldown > 0}
                                className={`px-4 py-1 text-center text-white font-semibold rounded-md transition duration-300 ease-in-out ${
                                    isSending || cooldown > 0 ? 'bg-gray-400 cursor-not-allowed' : 'bg-red-600 hover:bg-red-700'
                                }`}
                            >
                                {isSending
                                    ? "Sending..."
                                    : cooldown > 0
                                        ? `Wait ${cooldown}s`
                                        : "Verify Email"
                                }
                            </button>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default MenuComponent
