import React, {useState, useEffect, useRef} from 'react';
import {Tab} from '@headlessui/react';
import {FaCode, FaEye, FaMobileAlt, FaTabletAlt, FaDesktop, FaCopy, FaCheck} from 'react-icons/fa';

const CodeEditor = ({code, setCode}) => {
    // const [code, setCode] = useState('');
    const [output, setOutput] = useState('');
    const [previewSize, setPreviewSize] = useState('desktop');
    const [lineCount, setLineCount] = useState(1);
    const [copied, setCopied] = useState(false);
    const textareaRef = useRef(null);

    useEffect(() => {
        updateOutput();
        updateLineCount();
    }, [code]);

    const updateOutput = () => {
        setOutput(code);
    };

    const updateLineCount = () => {
        const lines = code.split('\n').length;
        setLineCount(lines);
    };

    const getPreviewSize = () => {
        switch (previewSize) {
            case 'mobile':
                return {width: '320px', height: '568px'};
            case 'tablet':
                return {width: '768px', height: '1024px'};
            default:
                return {width: '100%', height: '600px'};
        }
    };

    const handleScroll = (e) => {
        if (textareaRef.current) {
            const lineNumbers = textareaRef.current.previousSibling;
            lineNumbers.scrollTop = e.target.scrollTop;
        }
    };

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(output);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    return (
        <div className="flex flex-col space-y-3 py-4 sm:py-6 rounded-lg shadow-lg text-white">

            <h2 className="text-lg font-bold text-blue-400">HTML/CSS/JS Editor</h2>
            <span className={"text-sm bg-amber-200 w-fit text-black px-2 rounded"}>You should edit and write code on your IDE.</span>
            <div className="flex flex-col lg:flex-row gap-4">
                <div className="w-full lg:w-1/2 space-y-4">
                    <div
                        className="relative font-mono text-sm bg-[#1e1e1e] border-2 border-gray-700 rounded-md shadow-inner">
                        <div
                            className="absolute top-0 left-0 w-8 h-full bg-[#252525] text-gray-400 text-right pr-2 select-none overflow-hidden">
                            {Array.from({length: lineCount}, (_, i) => (
                                <div key={i + 1}>{i + 1}</div>
                            ))}
                        </div>
                        <textarea
                            ref={textareaRef}
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            onScroll={handleScroll}
                            placeholder="Enter your HTML, CSS, and JavaScript here..."
                            className="scrollbar-hide w-full h-svh  pl-10 resize-none border-none focus:ring-0 focus:outline-none bg-[#1e1e1e] text-white"
                        />
                    </div>
                </div>
                <div className="w-full lg:w-1/2 bg-[#1e1e1e] rounded-lg shadow-md overflow-hidden">
                    <Tab.Group>
                        <Tab.List className="flex p-1 space-x-1 bg-[#252525] rounded-xl">
                            <Tab
                                className={({selected}) =>
                                    `w-full py-2.5 text-sm leading-5 font-medium rounded-lg
                  focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60
                  ${selected ? 'bg-[#3a3a3a] text-white' : 'text-gray-400 hover:bg-[#2a2a2a] hover:text-white'}`
                                }
                            >
                                <div className="flex items-center justify-center">
                                    <FaEye className="w-4 h-4 mr-2"/>
                                    Preview
                                </div>
                            </Tab>
                            <Tab
                                className={({selected}) =>
                                    `w-full py-2.5 text-sm leading-5 font-medium rounded-lg
                  focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60
                  ${selected ? 'bg-[#3a3a3a] text-white' : 'text-gray-400 hover:bg-[#2a2a2a] hover:text-white'}`
                                }
                            >
                                <div className="flex items-center justify-center">
                                    <FaCode className="w-4 h-4 mr-2"/>
                                    Output Code
                                </div>
                            </Tab>
                        </Tab.List>
                        <Tab.Panels className="mt-2">
                            <Tab.Panel className="p-4">
                                <div className="flex justify-center space-x-2 mb-4">
                                    {['mobile', 'tablet', 'desktop'].map((size) => (
                                        <button
                                            key={size}
                                            onClick={() => setPreviewSize(size)}
                                            className={`p-2 rounded-md ${
                                                previewSize === size ? 'bg-blue-600 text-white' : 'bg-[#252525] text-gray-400'
                                            }`}
                                        >
                                            {size === 'mobile' && <FaMobileAlt className="w-4 h-4"/>}
                                            {size === 'tablet' && <FaTabletAlt className="w-4 h-4"/>}
                                            {size === 'desktop' && <FaDesktop className="w-4 h-4"/>}
                                        </button>
                                    ))}
                                </div>
                                <div className="flex justify-center overflow-auto m-auto">
                                    <iframe
                                        srcDoc={output}
                                        title="output"
                                        sandbox="allow-scripts allow-forms allow-modals"
                                        className={"rounded-md"}
                                        style={{
                                            ...getPreviewSize(),
                                            border: '2px solid #333',
                                            transform: previewSize !== 'desktop' ? 'scale(0.7)' : 'none',
                                            transformOrigin: 'top left'
                                        }}
                                    />
                                </div>
                            </Tab.Panel>
                            <Tab.Panel className="p-4">
                                <pre
                                    className="relative whitespace-pre-wrap text-sm font-mono bg-[#252525] p-4 rounded-md overflow-x-auto text-white">
                                    <button
                                        onClick={copyToClipboard}
                                        className="absolute top-0 right-0 flex items-center px-3 py-1 text-sm bg-[#3a3a3a] hover:bg-[#4a4a4a] rounded-md"
                                    >
                                        {copied ? <FaCheck className="w-4 h-4 mr-2"/> :
                                            <FaCopy className="w-4 h-4 mr-2"/>}
                                        {copied ? 'Copied!' : 'Copy Code'}
                                    </button>
                                    {output}
                                </pre>
                            </Tab.Panel>
                        </Tab.Panels>
                    </Tab.Group>
                </div>
            </div>
        </div>
    );
};

export default CodeEditor;