import { Field } from '@headlessui/react';
import { cn } from "../../../lib/utils";
import { FaChevronDown } from "react-icons/fa";
import React, { useState } from 'react';

const DropdownSortBy = ({ setSortBy }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('trending');

    const options = [
        { value: 'trending', label: 'Trending' },
        { value: 'latest', label: 'Recently posted' },
        { value: 'most-liked', label: 'Most likes' },
        { value: 'most-popular', label: 'Most popular' }
    ];

    const handleSelect = (value) => {
        setSelectedOption(value);
        setSortBy(value);
        setIsOpen(false);
    };

    return (
        <Field className="lg:w-[200px] md:w-[120px] sm:w-[100px]">
            <div className="relative">
                <div
                    onClick={() => setIsOpen(!isOpen)}
                    className={cn(
                        'mt-3 block w-full appearance-none rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white cursor-pointer',
                        'focus:outline-none focus:ring-2 focus:ring-white/25 focus:ring-offset-2 focus:ring-offset-transparent',
                        'transition duration-300 ease-in-out'
                    )}
                >
                    {options.find(opt => opt.value === selectedOption)?.label}
                </div>
                <FaChevronDown
                    className={cn(
                        "pointer-events-none absolute top-2.5 right-2.5 h-4 w-4 fill-white/60",
                        "transition-transform duration-300 ease-in-out",
                        isOpen ? "rotate-180" : "rotate-0"
                    )}
                    aria-hidden="true"
                />
                {isOpen && (
                    <div className="absolute z-10 w-full mt-1 bg-[#1E1E1E] rounded-lg shadow-lg">
                        {options.map((option) => (
                            <div
                                key={option.value}
                                className="px-3 py-2 text-white cursor-pointer hover:bg-white/10"
                                onClick={() => handleSelect(option.value)}
                            >
                                {option.label}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </Field>
    );
}

export default DropdownSortBy;