import React, {useState} from 'react';
import {IoIosSearch} from "react-icons/io";
import {useNavigate} from "react-router-dom";

const SearchBlogsContentLeft = () => {
    const [inputValue, setInputValue] = useState('');
    const navigate = useNavigate();


    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        navigate(`/blogs?q=${inputValue}`, {replace: true});
        window.location.reload();
    };

    return (
        <form className="max-w-md mx-auto mt-5" onSubmit={onSubmit}>
            <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none text-[#A1A1AA]">
                    <IoIosSearch/>
                </div>
                <input onChange={handleInputChange} value={inputValue}
                    type="search" id="default-search" style={{
                    background: "linear-gradient(90deg, #121212, #a1a1aa0f)",
                    transition: "all 0.5s ease-in-out"
                }}
                       className="block w-full p-3 ps-10 text-sm  border rounded-full border-gray-600 placeholder-[#A1A1AA] text-[#F4F4F5] focus:ring-[#6665F1] focus:border-[#6665F1]"
                       placeholder="Search Mockups, Logos..." required/>
            </div>
        </form>
    );
}

export default SearchBlogsContentLeft;
