import React from 'react'
import './sipnner.css';

export default function Spinner2() {
    return (
        <div className="loader2">
            <span>Loading...</span>
        </div>
    )
}
