import React, {useState, useEffect, useRef} from 'react';
import {FaTimes as X} from 'react-icons/fa';
import {API_BASE_URL} from "../../config";
import {useCookies} from "../../cookieContext";

const CategorySelect = ({selectedCategories, setSelectedCategories, blogId}) => {
    const [categories, setCategories] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef(null);
    const { cookies } = useCookies();

    useEffect(() => {
        void fetchCategories();
    }, []);

    useEffect(() => {
        if (inputValue) {
            const filtered = categories.filter(cat =>
                cat.toLowerCase().includes(inputValue.toLowerCase()) &&
                !selectedCategories.includes(cat)
            );
            setSuggestions(filtered);
        } else {
            setSuggestions([]);
        }
    }, [inputValue, selectedCategories, categories]);

    const fetchCategories = async () => {
        try {
            const response = await fetch(API_BASE_URL + '/api/v1/tag/all')
            const data = await response.json()
            setCategories(data.map((item) => item.name))
        } catch (error) {
            console.error(error)
        }
    }

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && inputValue) {
            const matchingCategory = categories.find(cat =>
                cat.toLowerCase() === inputValue.toLowerCase() &&
                !selectedCategories.includes(cat)
            );
            if (matchingCategory) {
                addCategory(matchingCategory);
            }
        }
    };

    const removeCategory = async (category) => {
        setSelectedCategories(selectedCategories.filter(cat => cat !== category));
        if (blogId !== null) {
            try {
                const response = await fetch(`${API_BASE_URL}/api/v1/blog-tag/edit/delete`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + cookies.token
                    },
                    body: JSON.stringify({
                        blogId: blogId,
                        tagName: category})
                });
                if (!response.ok) {
                    console.error('Failed to delete tag:', response);
                }
            } catch (error) {
                console.error('Error deleting tag:', error);
            }
        }
    };

    const addCategory = (category) => {
        if (!selectedCategories.includes(category)) {
            setSelectedCategories([...selectedCategories, category]);
            setInputValue('');
            inputRef.current?.focus();
        }
    };

    return (
        <div className="w-full mx-auto">
            <div
                className={`bg-gray-800 border ${isFocused ? 'border-[#6665F1]' : 'border-gray-700'} 
                rounded-lg p-2 flex flex-wrap items-center transition-all duration-300 ease-in-out
                shadow-sm hover:shadow-md`}
            >
                {selectedCategories.map((category, index) => (
                    <div key={index} className="bg-gradient-to-r from-[#6665F1] to-[#A556F6] text-white rounded-full px-3 py-1 m-1 flex items-center text-sm
                                  transition-all duration-200 ease-in-out hover:opacity-90">
                        {category}
                        <X
                            className="ml-2 h-4 w-4 cursor-pointer opacity-70 hover:opacity-100"
                            onClick={() => removeCategory(category)}
                        />
                    </div>
                ))}
                <input
                    ref={inputRef}
                    type="text"
                    className="bg-transparent outline-none text-[#e3eeff] flex-grow min-w-[50px] p-1"
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    placeholder={selectedCategories.length === 0 ? "Type a tag..." : ""}
                />
            </div>
            {suggestions.length > 0 && (
                <div className="absolute z-10 w-full max-w-md mt-1 bg-gray-800 border border-[#6665F1] rounded-lg shadow-lg
                    overflow-hidden transition-all duration-300 ease-in-out">
                    {suggestions.map((category, index) => (
                        <div
                            key={index}
                            className="px-4 py-2 hover:bg-gradient-to-r hover:from-[#6665F1] hover:to-[#A556F6] cursor-pointer text-[#e3eeff] transition-colors duration-150 ease-in-out"
                            onClick={() => addCategory(category)}
                        >
                            {category}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CategorySelect;