import React from 'react'
import {FollowerPointerCard} from "../../../ui/following-pointer";
import {getRelativeTime} from "../../utils/dateUtils";
import {GoHeartFill} from "react-icons/go";
import {Link} from "react-router-dom";
import DOMPurify from "dompurify";

function ItemPlayground({item}) {
    const relativeTime = getRelativeTime(item.updatedAt);

    const sanitizeHTML = (html) => ({
        __html: DOMPurify.sanitize(html, { ALLOWED_TAGS: [] })
    });

    return (
        <div className="w-full max-w-[23rem] mx-auto mb-5">
            <FollowerPointerCard
                title={
                    <TitleComponent
                        title={item.username}
                        avatar={item.profileImageUrl}
                    />
                }
            >
                <div className="relative overflow-hidden h-full rounded-2xl transition duration-200 group bg-[#121212] hover:shadow-xl border border-zinc-400 hover:border-zinc-100">
                    <div className="h-48 w-full bg-[#121212] rounded-tr-lg rounded-tl-lg overflow-hidden relative">
                        <img
                            src={item.imageUrl}
                            alt="thumbnail"
                            className="w-full h-full object-cover group-hover:scale-95 group-hover:rounded-2xl transition duration-200"
                        />
                    </div>
                    <div className="p-4">
                        <div className="my-2">
                            <span className="text-sm text-[#A1A1AA] mr-2">{relativeTime}</span>
                        </div>
                        <h2 className="font-bold my-2 text-lg text-[#F4F4F5] overflow-hidden text-ellipsis line-clamp-1">
                            {item.title}
                        </h2>
                        <div
                            className={`playground-item-content-rich-text font-normal my-2 text-sm text-[#A1A1AA] overflow-hidden text-ellipsis whitespace-pre-wrap blog-content w-full break-words line-clamp-3`}
                            dangerouslySetInnerHTML={sanitizeHTML(item.description)}
                        />
                        <div className="flex flex-row justify-between items-center mt-4">
                            <span
                                className="flex items-center gap-1 text-sm text-[#A1A1AA]"><GoHeartFill/> {item.likeCount}</span>
                            <Link to={`/${item.username}/e/${item.slug}`}
                                  className="cursor-none relative z-10 px-6 py-2 bg-[#F4F4F5] text-[#121212] font-bold rounded-xl block text-xs">
                                Read More
                            </Link>
                        </div>
                    </div>
                </div>
            </FollowerPointerCard>
        </div>
    );
}


const TitleComponent = ({title, avatar}) => (
    <div className="flex space-x-2 items-center">
        {/* Ensure the Image component is imported or replace it with a standard img tag */}
        <img
            src={avatar}
            height="20"
            width="20"
            alt="avatar"
            className="rounded-full w-5 h-5 border"
        />
        <p>{title}</p>
    </div>
);

export default ItemPlayground