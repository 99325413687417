import React, {useState} from 'react'
import {TbMoodLookDown} from "react-icons/tb";
import {API_BASE_URL} from "../../../../config";
import AddComment from "../../../utils/AddComment";
import ItemComment from "../../../utils/ItemComment";

function CommentPlaygroundDetail({playId, navigation, emailLocal, tokenLocal, setCommentCount, commentList, setCommentList}) {
    const [userComment, setUserComment] = useState('')
    const [replyComment, setReplyComment] = useState({
        status: false,
        commentId: null
    })

    const handleDeleteComment = async (commentId) => {
        try {
            const response = await fetch(`${API_BASE_URL}/api/v1/playground/comment/edit/delete/${commentId}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${tokenLocal}`
                }
            });
            if (!response.ok) {
                throw new Error("Error deleting comment");
            }
            setCommentList(prevComments => prevComments.filter(comment => comment.id !== commentId));
        } catch (error) {
            console.error("Error deleting comment:", error);
        }
    }

    const handleInputChange = (e) => {
        setUserComment(e.target.value)
    }

    const handleAddComment = async (parentCommentId) => {
        if (!emailLocal || !tokenLocal) {
            navigation("/login")
            return;
        }
        try {
            const request = {
                playId,
                email: emailLocal,
                parentCommentId,
                content: userComment
            }
            const response = await fetch(`${API_BASE_URL}/api/v1/playground/comment/edit/add`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${tokenLocal}`
                },
                body: JSON.stringify(request)
            })
            if (!response.ok) {
                throw new Error("Error adding comment")
            }
            const data = await response.json()
            setCommentList(prevComments => [...prevComments, data])
            setUserComment('')
            setReplyComment({status: false, commentId: null})
        } catch (error) {
            console.error("Error adding comment:", error)
        }
    }

    const handleReply = (commentId) => {
        setReplyComment(prev => ({
            status: prev.commentId !== commentId || !prev.status,
            commentId: commentId
        }))
    }

    return (
        <div className={"w-full"}>
            <div className="p-4 md:p-5 space-y-3">
                <div className="w-full">
                    {commentList.length === 0 && (
                        <h1 className="text-center text-lg mt-4">No comments yet</h1>
                    )}
                    <div className="px-2 pb-2 space-y-3 overflow-y-auto max-h-[500px] scrollbar-hide">
                        {
                            commentList
                                .filter(comment => !comment.playgroundPostComment) // Only top-level comments
                                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                                .map((item) => (
                                    <div key={item.id} className={"parent-comment w-full"}>
                                        {
                                            item.status === "DELETED" ? (
                                                <div
                                                    className="flex items-center justify-center text-center bg-[#A1A1AA10] p-4 rounded-lg gap-1 text-sm text-[#A1A1AA98] h-full">
                                                    <TbMoodLookDown/> This comment has been removed.
                                                </div>
                                            ) : (

                                                <ItemComment
                                                    handleDelete={() => handleDeleteComment(item.id)}
                                                    name={item.user.displayName}
                                                    content={item.content}
                                                    updateAt={item.updatedAt}
                                                    imageUser={item?.user.profileImageUrl}
                                                    commentUser={item.user.email}
                                                    username={item.user.username}
                                                    handleReply={() => handleReply(item.id)}
                                                />
                                            )
                                        }

                                        {commentList
                                            .filter(comment => comment.playgroundPostComment?.id === item.id)
                                            .map(childComment => (
                                                <div key={childComment.id}
                                                     className="child-comment pt-2 ml-1 pl-6 border-l border-[#A1A1AA40]">
                                                    {
                                                        childComment.status === "DELETED" ? (
                                                            <div
                                                                className="flex items-center justify-center text-center bg-[#A1A1AA10] p-4 rounded-lg gap-1 text-sm text-[#A1A1AA98] h-full">
                                                                <TbMoodLookDown/> This comment has been removed.
                                                            </div>

                                                        ) : (
                                                            <ItemComment
                                                                handleDelete={() => handleDeleteComment(childComment.id)}
                                                                name={childComment.user.displayName}
                                                                content={childComment.content}
                                                                updateAt={childComment.updatedAt}
                                                                imageUser={childComment?.user.profileImageUrl}
                                                                commentUser={childComment.user.email}
                                                                username={childComment.user.username}
                                                                handleReply={() => handleReply(item.id)}
                                                            />
                                                        )
                                                    }

                                                </div>
                                            ))
                                        }
                                        <div
                                            className={replyComment.commentId === item.id && replyComment.status ? "child-comment pt-2 ml-1 pl-6 border-l border-[#A1A1AA]" : "hidden"}>
                                            <AddComment
                                                className={replyComment.commentId === item.id && replyComment.status ? 'mt-3' : 'hidden'}
                                                onChange={handleInputChange}
                                                onclick={() => handleAddComment(item.id)}
                                                tokenLocal={tokenLocal}
                                            />
                                        </div>
                                    </div>
                                ))
                        }
                    </div>
                </div>
            </div>
            <div
                className="flex items-center p-4 border-t rounded-b border-[#F4F4F570]">
                <div className="flex items-center w-full text-white">
                    <AddComment onChange={handleInputChange} onclick={() => handleAddComment(null)}
                                tokenLocal={tokenLocal} value={userComment}/>
                </div>
            </div>
        </div>

    )
}

export default CommentPlaygroundDetail