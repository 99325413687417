import React, {Fragment, useCallback, useEffect, useState} from 'react'
import MenuComponent from "../utils/menu/MenuComponent";
import './BlogStyle.css';
import HeaderBlogsContentCenter from "./item/HeaderBlogsContentCenter";
import SearchBlogsContentLeft from "./item/SearchBlogsContentLeft";
import {FaChartLine, FaGoogleWallet} from "react-icons/fa";
import {AiOutlineUsergroupAdd} from "react-icons/ai";
import FooterComponent from "../utils/footer/FooterComponent";
import {BsBookmarks} from "react-icons/bs";
import RecentItem from "./item/RecentItem";
import {useParams} from "react-router-dom";
import ItemBlogList from "./item/ItemBlogList";
import {FaAnglesRight, FaArrowLeftLong} from "react-icons/fa6";
import {API_BASE_URL} from "../../config";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner2 from "../utils/sipnner/Spinner2";
import {useCookies} from "../../cookieContext";
import StructuredData from "../utils/StructuredData";
import { useSearchParams } from 'react-router-dom';

const BlogsComponent = () => {
    const [searchParams] = useSearchParams();
    const tagNameParams = searchParams.get('tag');
    const query = searchParams.get('q');
    const {sortByParams} = useParams();

    const [leftContentVisible, setLeftContentVisible] = useState(false);
    const [blogs, setBlogs] = useState([]);
    const [listTag, setListTag] = useState([]);
    const [listRecent, setListRecent] = useState([]);

    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);

    const {cookies} = useCookies();

    const usernameLocal = cookies.username
    const tokenLocal = cookies.token

    const fetchUserRecent = useCallback(async () => {
        try {
            setLoading(true);
            const response = await fetch(`${API_BASE_URL}/api/v1/user/get/recent/${usernameLocal}`);
            const data = await response.json();
            setListRecent(data);
        } catch (e) {
            console.error('Error fetching data:', e);
        } finally {
            setLoading(false);
        }
    }, [usernameLocal]);

    const fetchTags = useCallback(async () => {
        try {
            setLoading(true);
            const response = await fetch(`${API_BASE_URL}/api/v1/blog-tag/get/top`);
            const data = await response.json();
            setListTag(data);
        } catch (e) {
            console.error('Error fetching data:', e);
        } finally {
            setLoading(false);
        }
    }, []);

    const fetchData = useCallback(async (url) => {
        try {
            setLoading(true);
            const response = await fetch(url);
            const data = await response.json();
            if (data._embedded?.blogPostList) {
                // setBlogs(data._embedded.blogPostList);
                setBlogs(prevBlogs => [...prevBlogs, ...data._embedded.blogPostList]);

                setHasMore(data.page?.number < data.page?.totalPages - 1);
            } else {
                setBlogs([]);
                setHasMore(false);
            }
        } catch (e) {
            console.error('Error fetching data:', e);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        let url;
        if (sortByParams === 'popular') {
            url = `${API_BASE_URL}/api/v1/blog/get/by/popular?page=${page}&size=5`;
        } else if (sortByParams === 'all') {
            url = `${API_BASE_URL}/api/v1/blog/get/by/all?page=${page}&size=5`;
        }else if (tagNameParams !== null) {
            url = `${API_BASE_URL}/api/v1/blog/get/tag/${tagNameParams}?page=${page}&size=5`
        } else if (query) {
            url = `${API_BASE_URL}/api/v1/blog/get/search/${query}?page=${page}&size=5`;
        } else if (sortByParams === 'saved' && usernameLocal) {
            url = `${API_BASE_URL}/api/v1/blog/get/saved/${usernameLocal}?page=${page}&size=5`;
        } else if (sortByParams === 'following' && usernameLocal) {
            url = `${API_BASE_URL}/api/v1/blog/get/following/${usernameLocal}?page=${page}&size=5`;
        } else {
            url = `${API_BASE_URL}/api/v1/blog/get/by/all?page=${page}&size=5`;
        }

        void fetchUserRecent();
        void fetchTags();
        void fetchData(url);
    }, [sortByParams, page, fetchData, tagNameParams, usernameLocal]);

    const toggleLeftContent = () => {
        setLeftContentVisible(!leftContentVisible);
    };

    const structuredData = {
        "@context": "https://schema.org",
        "@type": "CollectionPage",
        "mainEntity": {
            "@type": "ItemList",
            "itemListElement": blogs.slice(0, 10).map((blog, index) => ({
                "@type": "ListItem",
                "position": index + 1,
                "item": {
                    "@type": "BlogPosting",
                    "headline": blog.title,
                },
                "url": `https://w404.net/${blog.username}/b/${blog.slug}`
            }))
        },
        "url": "https://w404.net/blogs",
        "name": "W404 Blog",
        "description": "The best blog for you to read, learn and share, with a lot of useful information and knowledge. Let's join us! Create amazing blogs and share your knowledge with the world!",
        "publisher": {
            "@type": "Organization",
            "name": "W404",
            "logo": {
                "@type": "ImageObject",
                "url": "https://w404.net/assets/images/logo1.png"
            }
        }
    };

    return (
        <Fragment>
            <StructuredData data={structuredData} title={"W404 Blog"} url={"https://w404.net/blogs"}
                            description={"The best blog for you to read, learn and share, with a lot of useful information and knowledge. Let's join us! Create amazing blogs and share your knowledge with the world!"}/>
            <MenuComponent/>
            <div className="container">
                <div className="blogs-content grid grid-cols-12 blogs-body mb-5">
                    <button onClick={toggleLeftContent} className={"blogs-content-icon"}>
                        <FaAnglesRight/>
                    </button>
                    <div
                        className={leftContentVisible ? 'blogs-content-left active' : 'blogs-content-left'}>
                        <div className="blogs-content-left-menu pb-4">
                            <div className="blogs-content-left-btn-active flex justify-end mt-4 -mb-1">
                                <button
                                    onClick={toggleLeftContent}
                                    className="blogs-content-icon-btn-left ml-auto">
                                    <FaArrowLeftLong/>
                                </button>
                            </div>
                            <SearchBlogsContentLeft/>
                            <hr className="mb-6 sm:mx-auto border-[#a1a1aa23] lg:my-8"
                                style={{marginTop: "23px", borderTopWidth: "2px"}}/>
                            <a href={`/blogs/all`} className={sortByParams === 'all' ? "blogs-btn-active" : "blogs-content-left-btn"}>
                                <FaGoogleWallet
                                    className={"blogs-content-left-btn-icon inline mr-2 origin-center hover:origin-top"}/>All
                            </a>
                            <a href={`/blogs/popular`} className={sortByParams === 'popular' ? "blogs-btn-active" : "blogs-content-left-btn"}>
                                <FaChartLine className={"blogs-content-left-btn-icon inline mr-2"}/>Popular
                            </a>

                            {
                                usernameLocal && tokenLocal && (
                                    <Fragment>
                                        <a href={`/blogs/following`} className={sortByParams === 'following' ? "blogs-btn-active" : "blogs-content-left-btn"}>
                                            <AiOutlineUsergroupAdd className={"blogs-content-left-btn-icon inline mr-2"}/>Following
                                        </a>
                                        <hr className="sm:mx-auto border-[#a1a1aa23] lg:my-8"
                                            style={{marginTop: "5px", marginBottom: "17px", borderTopWidth: "2px"}}/>
                                        <a href={`/blogs/saved`} className={sortByParams === 'saved' ? "blogs-btn-active" : "blogs-content-left-btn"}>
                                            <BsBookmarks className={"blogs-content-left-btn-icon inline mr-2"}/>Saved
                                        </a>
                                        <div className="blogs-content-left-recent w-full">
                                            <hr className="sm:mx-auto border-[#a1a1aa23] lg:my-8"
                                                style={{marginTop: "0px", marginBottom: "10px", borderTopWidth: "2px"}}/>
                                            <span className={"text-xl text-[#A1A1AA]"}>Recent</span>
                                            {
                                                listRecent.map((recent, index) => (
                                                    <RecentItem key={index} recentItem={recent}/>
                                                ))
                                            }
                                        </div>
                                    </Fragment>
                                )
                            }
                        </div>
                    </div>
                    {/*Blogs content center*/}
                    <div className="blogs-content-center col-span-7 h-fit px-2">
                        <div className="w-full py-6" style={{borderBottom: "0.1px solid #A1A1AA"}}>
                            <HeaderBlogsContentCenter/>
                        </div>

                        <div id={"scrollBlogs"}
                             className="grid grid-cols-1 gap-5 px-3 mt-5 h-screen overflow-y-scroll scrollbar-hide">
                            <InfiniteScroll
                                dataLength={blogs.length}
                                next={() => setPage(page + 1)}
                                hasMore={hasMore}
                                loader={
                                    loading ? (
                                        <div className="w-full h-svh">
                                            <Spinner2/>
                                        </div>
                                    ) : null
                                }
                                endMessage={
                                    <p className="w-full text-center text-[#A1A1AA]">Yay! You have seen it all</p>
                                }
                                scrollableTarget={"scrollBlogs"}
                                style={{display: 'flex', flexDirection: 'column', gap: '18px'}}
                                className={"scrollbar-hide"}
                            >
                                {blogs.map((blog, index) => (
                                    <ItemBlogList
                                        key={index}
                                        shortDescription={blog.shortDescription}
                                        slug={blog.slug}
                                        title={blog.title}
                                        viewCount={blog.viewCount}
                                        imageUrl={blog.imageUrl}
                                        createdAt={blog.updatedAt}
                                        user={blog.user}
                                        status={blog.status}
                                    />
                                ))}
                            </InfiniteScroll>


                        </div>
                    </div>
                    {/*Blogs content right*/}
                    <div className="blogs-content-right col-span-2 h-full py-3">
                        <div className="border w-full h-fit rounded-2xl py-3 px-2">
                            <span className={"text-[17px] font-semibold text-[#F4F4F5] ml-4 mt-2"}> For you</span>
                            <div className="grid grid-cols-1 gap-2 mt-4">
                                {
                                    listTag.map((tag, index) => (
                                        <a key={index} href={`/blogs?tag=${tag}`}
                                              className="w-full py-2 pl-4 rounded text-[#A1A1AA] hover:bg-[#a1a1aa13] transition ease-in-out duration-300">
                                            {tag}
                                        </a>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <FooterComponent/>
        </Fragment>
    )

}

export default BlogsComponent
