import React, {Component} from 'react';
import {RetroGrid} from "../../../ui/retro-grid";

class HeaderPlayGround extends Component {
    render() {
        return (
            <div className="relative flex h-full w-full items-center justify-center overflow-hidden p-10 mt-2">
                <span
                    className="z-10 playground-header-text whitespace-pre-wrap bg-gradient-to-b from-[#6665F1] via-[#A556F6] to-[#F4F4F5] bg-clip-text ">
                    Find & Use The Best Assistants
                </span>
                <RetroGrid/>
            </div>
        );
    }
}

export default HeaderPlayGround;