import React, { useEffect, useState } from 'react';

const Warning = ({ message, onClose, index }) => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(false);
            onClose();
        }, 5000);

        return () => clearTimeout(timer);
    }, [onClose]);

    if (!isVisible) return null;

    return (
        <div
            className={`z-10 fixed bottom-5 right-5 bg-gray-800 text-white p-4 rounded-lg shadow-lg max-w-sm flex items-center transition-all duration-300 ease-in-out`}
            style={{ transform: `translateY(${-index * 70}px)` }}
        >
            <svg
                className="w-6 h-6 mr-4 flex-shrink-0"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill="#ffa500"
                    d="M12 2L1 21h22L12 2zm0 3.516L20.297 19H3.703L12 5.516zM11 16h2v2h-2v-2zm0-7h2v5h-2V9z"
                />
            </svg>
            <span className="text-base">{message} {" + "} {index}</span>
        </div>
    );
};

export default Warning;