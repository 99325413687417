import React, {Fragment, useCallback, useEffect, useState} from 'react';
import MenuComponent from "../utils/menu/MenuComponent";
import ShineBorder from "../../ui/shine-border";
import {FiUsers} from "react-icons/fi";
import {RiBloggerLine} from "react-icons/ri";
import {MdDisplaySettings} from "react-icons/md";
import {FaFacebookF, FaRegComments} from "react-icons/fa";
import {Link, useNavigate, useParams} from "react-router-dom";
import {GoUnlink} from "react-icons/go";
import {IoClose} from "react-icons/io5";
import {Button, Field, Input, Label, Textarea} from '@headlessui/react'
import {cn} from "../../lib/utils";
import {CiCamera, CiFaceMeh} from "react-icons/ci";
import './AccountComponent.css';
import FooterComponent from "../utils/footer/FooterComponent";
import {API_BASE_URL} from "../../config";
import ImageComponent from "../utils/ImageComponent";
import ItemBlogList from "../blog/item/ItemBlogList";
import ItemPlayground from "../playground/item/ItemPlayground";
import {useCookies} from "../../cookieContext";
import StructuredData from "../utils/StructuredData";

const AccountComponent = () => {
    const {username} = useParams();
    const [accountSettingVisible, setAccountSettingVisible] = useState(false);
    const [activeTab, setActiveTab] = useState('playground');
    const {cookies, setCookie} = useCookies()

    const [user, setUser] = useState({});
    const [listBlogs, setListBlogs] = useState([]);
    const [listExplores, setListExplores] = useState([]);
    const [editProfile, setEditProfile] = useState({
        displayName: "",
        username: "",
        fbLink: "",
        websiteUrl: "",
        bio: "",
    });
    const [error, setError] = useState({
        displayName: false,
        username: false,
        validUsername: null,
    });
    let [usernameCheckTimeout] = useState(null);

    const [imageUrl, setImageUrl] = useState('');
    const [isFollowing, setIsFollowing] = useState(false);


    const [isUserDataLoaded, setIsUserDataLoaded] = useState(false);
    const navigate = useNavigate();
    const emailLocal = cookies.email;
    const tokenLocal = cookies.token;
    const usernameLocal = cookies.username;

    const handleFollowAction = async (action) => {
        if (!tokenLocal) {
            navigate('/login');
            return;
        }

        const endpoint = action === 'follow'
            ? `${API_BASE_URL}/api/v1/follow/edit/add`
            : `${API_BASE_URL}/api/v1/follow/edit/delete/${user.username}/${emailLocal}`;

        const method = action === 'follow' ? 'POST' : 'DELETE';
        const body = action === 'follow' ? JSON.stringify({
            follower: user.email,
            followed: emailLocal
        }) : undefined;

        try {
            const response = await fetch(endpoint, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokenLocal}`
                },
                body
            });

            if (!response.ok) {
                throw new Error(`Error ${action === 'follow' ? 'following' : 'unfollowing'} user: ${response.status}`);
            }

            setIsFollowing(action === 'follow');
        } catch (err) {
            console.error(err);
        }
    };

    const handleUpdateProfile = async () => {
        try {
            let user = {
                oldUsername: username,
                displayName: editProfile.displayName,
                newUsername: editProfile.username,
                fbLink: editProfile.fbLink,
                websiteUrl: editProfile.websiteUrl,
                bio: editProfile.bio,
                profileImageUrl: imageUrl,
                token: cookies.token
            }
            const response = await fetch(`${API_BASE_URL}/api/v1/user/edit/update-profile`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokenLocal}`
                },
                body: JSON.stringify(user)
            });
            if (!response.ok) {
                throw new Error(`Error updating profile: ${response.status}`);
            }
            setCookie('username', editProfile.username);
            setCookie('profileImageUrl', imageUrl);
        } catch (error) {
            console.error('Error updating profile:', error);
        } finally {
            setAccountSettingVisible(prevState => !prevState);
            navigate(`/a/${editProfile.username}`);
            await new Promise(resolve => setTimeout(resolve, 500));
            window.location.reload();
        }
    }

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('image', file);
            formData.append('email', cookies.email);

            fetch(`${API_BASE_URL}/api/v1/image/upload`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + cookies.token
                },
                body: formData
            })
                .then(response => response.json())
                .then(data => {
                    setImageUrl(API_BASE_URL + data.url); // Prepend API_BASE_URL to the relative URL
                })
                .catch(error => console.error('Error:', error));
        }
    };

    const fetchData = useCallback(async (url, errorMessage) => {
        const response = await fetch(url);
        if (!response.ok) {
            navigate('/404');
            throw new Error(`${errorMessage}: ${response.status}`);
        }
        return response.json();
    }, []);

    const handleCheckUsername = useCallback(async (value) => {
        if (value === user.username && !error.username && value.length > 5) {
            setError({...error, validUsername: null});
        } else if (value.length > 0) {
            const data = await fetchData(`${API_BASE_URL}/api/v1/user/get/check/${value}`, 'Error checking username');
            setError({...error, validUsername: data});
        }
    }, [user, error, fetchData]);

    const fetchExplores = useCallback(async () => {
        const data = await fetchData(`${API_BASE_URL}/api/v1/playground/get/all/user/${username}`, 'Error fetching explores');
        setListExplores(data);
    }, [username, fetchData]);

    const fetchBlogs = useCallback(async () => {
        if (username === usernameLocal) {
            const data = await fetchData(`${API_BASE_URL}/api/v1/blog/get/all/${username}`, 'Error fetching blogs');
            setListBlogs(data);
        } else {
            const data = await fetchData(`${API_BASE_URL}/api/v1/blog/get/${username}`, 'Error fetching blogs');
            setListBlogs(data);
        }
    }, [username, usernameLocal, fetchData]);

    const fetchUserData = useCallback(async () => {
        const data = await fetchData(`${API_BASE_URL}/api/v1/user/get/${username}`, 'Error fetching followers');
        setUser(data);
    }, [username, fetchData]);

    const fetchIsUserFollowing = useCallback(async () => {
        const data = await fetchData(`${API_BASE_URL}/api/v1/follow/get/${user.username}/${emailLocal}`, 'Error fetching follow status');
        setIsFollowing(data);
    }, [user, emailLocal, fetchData]);

    useEffect(() => {
        if (!isUserDataLoaded) {
            void fetchUserData();
            void fetchBlogs();
            void fetchExplores();
            setIsUserDataLoaded(true);
        }
    }, [fetchUserData, fetchBlogs, fetchExplores, isUserDataLoaded]);

    useEffect(() => {
        if (isUserDataLoaded && user.username) {
            void fetchIsUserFollowing();
        }
    }, [isUserDataLoaded, user.username, fetchIsUserFollowing]);
    const toggleAccountSettingVisible = () => {
        setEditProfile({
            displayName: user.displayName,
            username: user.username,
            fbLink: user.fbLink,
            websiteUrl: user.websiteUrl,
            bio: user.bio,
        })
        setImageUrl(user.profileImageUrl);
        setAccountSettingVisible(prevState => !prevState);
    }

    const structuredData = {
        "@context": "https://schema.org",
        "@type": "ProfilePage",
        "url": `https://w404.net/a/${user.username}`,
        "name": user.displayName,
        "description": user.bio,
        "image": user.profileImageUrl,
        "sameAs": [
            user.fbLink,
            user.websiteUrl
        ]
    };


    return (
        <Fragment>
            <StructuredData data={structuredData} title={user.displayName} url={`https://w404.net/a/${user.username}`}
                            description={user.bio ? user.bio : user.displayName}/>
            <MenuComponent/>
            <div className={"container"}>
                <div className="grid h-full  grid-cols-12 mt-4 mb-8">
                    <div className="account-content-left min-h-[70dvh]">
                        <div className="flex flex-col items-center mt-12">
                            <ImageComponent className={"w-12 h-12"} image={user.profileImageUrl}
                                            alt={user.displayName}/>
                        </div>
                        <div className="mt-4 text-center">
                            <div className="text-[#F4F4F5] text-xl font-semibold">{user.displayName}</div>
                            <div className="text-[#A1A1AA] text-sm">@{user.username}</div>
                            <div className="text-[#A1A1AA] text-sm mt-2">
                                {user.bio}
                            </div>
                        </div>
                        <div className="px-8 mt-5">
                            {/*<ShineBorder*/}
                            {/*    className="text-center px-4 py-2 capitalize cursor-pointer">*/}
                            {/*    Follow*/}
                            {/*</ShineBorder>*/}

                            {
                                !emailLocal ? (
                                    <ShineBorder
                                        className={"text-center px-4 py-2 capitalize opacity-50 cursor-not-allowed"}>Login
                                        to Follow</ShineBorder>
                                ) : user.email === emailLocal ? (
                                    <ShineBorder
                                        className={"text-center px-4 py-2 capitalize opacity-50 cursor-not-allowed"}>Follow</ShineBorder>
                                ) : isFollowing ? (
                                    <ShineBorder onClick={() => handleFollowAction('unfollow')}
                                                 className={"text-center px-4 py-2 capitalize cursor-pointer"}>Following</ShineBorder>
                                ) : (
                                    <ShineBorder onClick={() => handleFollowAction('follow')}
                                                 className={"text-center px-4 py-2 capitalize cursor-pointer"}>Follow</ShineBorder>
                                )
                            }

                            {
                                user.email === emailLocal && (
                                    <button onClick={toggleAccountSettingVisible}
                                            className={"text-[#A1A1AA] mt-3 w-full py-2 rounded-lg border hover:text-[#F4F4F5] duration-300"}>Edit
                                        Profile
                                    </button>
                                )
                            }

                        </div>
                        <div className="mt-9 px-2 py-4"
                             style={{borderBottom: "1px solid #A1A1AA", borderTop: "1px solid #A1A1AA"}}>
                            <div className="text-[#A1A1AA] flex justify-between items-center">
                                <div className="flex flex-col items-center">
                                    <div className="flex items-center">
                                        <FiUsers/>
                                        <span className="ml-1">{user.followerCount}</span>
                                    </div>
                                    <span className="text-xs uppercase mt-1">Followers</span>
                                </div>

                                <div className="flex flex-col items-center">
                                    <div className="flex items-center">
                                        <RiBloggerLine/>
                                        <span className="ml-1">{listBlogs.length}</span>
                                    </div>
                                    <span className="text-xs uppercase mt-1">Blogs</span>
                                </div>

                                <div className="flex flex-col items-center">
                                    <div className="flex items-center">
                                        <MdDisplaySettings/>
                                        <span className="ml-1">{listExplores.length}</span>
                                    </div>
                                    <span className="text-xs uppercase mt-1">Explores</span>
                                </div>

                                <div className="flex flex-col items-center">
                                    <div className="flex items-center">
                                        <FaRegComments/>
                                        <span className="ml-1">{user.commentCount}</span>
                                    </div>
                                    <span className="text-xs uppercase mt-1">Comments</span>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5">
                            {
                                user.fbLink?.length > 0 && (
                                    <div className="flex items-center text-[#A1A1AA] overflow-hidden">
                                        <FaFacebookF className={"mr-2"}/>
                                        <Link to={user.fbLink}>{user.fbLink}</Link>
                                    </div>
                                )
                            }
                            {
                                user.websiteUrl?.length > 0 && (
                                    <div className="flex items-center text-[#A1A1AA] overflow-hidden mt-3">
                                        <GoUnlink className={"mr-2"}/>
                                        <Link to={user.websiteUrl}>{user.websiteUrl}</Link>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className="account-content-right">
                        <div className="flex mb-4 justify-end">
                            <button
                                className={`text-xl px-4 py-2 font-semibold ${
                                    activeTab === 'playground' ? 'text-[#F4F4F5] border-b-2 border-[#F4F4F5]' : 'text-[#A1A1AA]'
                                }`}
                                onClick={() => setActiveTab('playground')}
                            >
                                Explore
                            </button>
                            <button
                                className={`text-xl px-4 py-2 font-semibold ${
                                    activeTab === 'blog' ? 'text-[#F4F4F5] border-b-2 border-[#F4F4F5]' : 'text-[#A1A1AA]'
                                }`}
                                onClick={() => setActiveTab('blog')}
                            >
                                Blog
                            </button>
                        </div>

                        {activeTab === 'playground' && (
                            <div className="account-playground-content my-5">

                                {
                                    listExplores.length > 0 ? (
                                        listExplores.map((item, index) => (
                                            <ItemPlayground key={index} item={item}/>
                                        ))
                                    ) : (
                                        <div className={"text-[#A1A1AA] w-full"}>
                                        <span className={"items-center gap-1 flex justify-center underline"}>
                                            None yet or None published yet. <CiFaceMeh/>
                                        </span>
                                        </div>
                                    )
                                }

                            </div>
                        )}

                        {activeTab === 'blog' && (
                            <div className={"flex px-10 my-5 transform duration-300"}
                                 style={{flexWrap: "wrap", flexDirection: "row", gap: "18px"}}>
                                {
                                    listBlogs.length > 0 ? (
                                        listBlogs.map((blog, index) => (
                                            <ItemBlogList
                                                key={index}
                                                shortDescription={blog.shortDescription}
                                                slug={blog.slug}
                                                title={blog.title}
                                                viewCount={blog.viewCount}
                                                imageUrl={blog.imageUrl}
                                                createdAt={blog.updatedAt}
                                                user={blog.user}
                                                status={blog.status}
                                            />
                                        ))
                                    ) : (
                                        <div className={"text-[#A1A1AA] w-full"}>
                                        <span className={"items-center gap-1 flex justify-center underline"}>
                                            None yet or None published yet. <CiFaceMeh/>
                                        </span>
                                        </div>
                                    )
                                }
                            </div>
                        )}
                    </div>
                </div>


                {/*Modal Account Setting*/}
                {
                    accountSettingVisible && (
                        <div id="default-modal"
                             className={"flex items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[100] w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"}>
                            <div className="relative p-4 w-full max-w-2xl max-h-full">
                                {/*Modal content */}
                                <div className="relative rounded-2xl shadow bg-[#121212]">
                                    {/*<!-- Modal header*/}
                                    <div
                                        className="flex items-center justify-between pt-5 pb-2 px-5 border-b rounded-t border-gray-600">
                                        <h3 className="text font-semibold text-[#F4F4F5]">
                                            Account Settings
                                        </h3>
                                        <button onClick={toggleAccountSettingVisible} type="button"
                                                className="text-[#A1A1AA] text-xl rounded-lg w-8 h-8 ms-auto inline-flex justify-center items-center hover:text-white duration-300"
                                                data-modal-hide="default-modal">
                                            <IoClose/>
                                            <span className="sr-only">Close modal</span>
                                        </button>
                                    </div>
                                    {/*<!-- Modal body*/}
                                    <div className="p-4 md:p-5 space-y-3">
                                        <div className="flex flex-col items-center mt-3 relative">
                                            <div className="relative">
                                                <ImageComponent
                                                    className="w-14 h-[3.5rem] object-cover overflow-hidden"
                                                    image={imageUrl}
                                                    alt={user.displayName}
                                                />
                                                <label htmlFor="fileInput"
                                                       className="tow absolute inset-0 flex justify-center items-center"
                                                >
                                                    <div
                                                        className="text-white bg-gray-500 bg-opacity-50 text-3xl rounded cursor-pointer p-1 hover:bg-opacity-75 transition-all">
                                                        <CiCamera/>
                                                    </div>
                                                </label>
                                            </div>
                                            <input
                                                id="fileInput"
                                                type="file"
                                                accept="image/*"
                                                className="hidden"
                                                onChange={handleImageUpload}
                                            />
                                        </div>

                                        <Field>
                                            <Label className="text-sm/6 font-medium text-white">Name</Label>
                                            <Input onChange={(e) => {
                                                const newName = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
                                                setEditProfile({...editProfile, displayName: newName})
                                                setError({...error, displayName: newName.length > 0 && newName.length < 5})
                                            }}
                                                   value={editProfile.displayName}
                                                   minLength={5}
                                                   maxLength={150}
                                                   className={cn(
                                                       'mt-3 block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white',
                                                       'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                                                   )}
                                            />
                                            {
                                                error.displayName && (
                                                    <span
                                                        className={"text-sm text-red-400 mt-1"}>Name need longer than 5 characters!</span>
                                                )
                                            }
                                        </Field>
                                        <Field>
                                            <Label className="text-sm/6 font-medium text-white">Username</Label>
                                            <Input
                                                onChange={(e) => {
                                                    const newUsername = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '').trim();
                                                    setEditProfile(prevState => ({...prevState, username: newUsername}));
                                                    setError(prevError => ({
                                                        ...prevError,
                                                        username: newUsername.length < 5
                                                    }));

                                                    clearTimeout(usernameCheckTimeout);
                                                    usernameCheckTimeout = setTimeout(() => {
                                                        if (newUsername.length >= 5) {
                                                            void handleCheckUsername(newUsername);
                                                        }
                                                    }, 900);
                                                }}
                                                value={editProfile.username}
                                                minLength={5}
                                                maxLength={150}
                                                className={cn(
                                                    'mt-3 block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white',
                                                    'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                                                )}
                                            />
                                            {
                                                editProfile.username.length > 0 && editProfile.username.length < 5 && (
                                                    <span className="text-sm text-red-400 mt-1">Username needs to be longer than 5 characters!</span>
                                                )
                                            }

                                            {
                                                error.validUsername !== undefined && user.username !== editProfile.username && error.username === false && editProfile.username.length > 0 && (
                                                    error.validUsername ? (
                                                        <span
                                                            className="block text-sm text-green-400 mt-1">Username is available!</span>
                                                    ) : (
                                                        <span
                                                            className="block text-sm text-red-400 mt-1">Username is already taken!</span>
                                                    )
                                                )
                                            }

                                        </Field>
                                        <Field>
                                            <Label className="text-sm/6 font-medium text-white">Facebook (optional)</Label>
                                            <Input
                                                onChange={(e) => setEditProfile({...editProfile, fbLink: e.target.value})}
                                                value={editProfile.fbLink || ''}
                                                minLength={2}
                                                maxLength={200}
                                                className={cn(
                                                    'mt-3 block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white',
                                                    'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                                                )}
                                            />
                                        </Field>
                                        <Field>
                                            <Label className="text-sm/6 font-medium text-white">Link (optional)</Label>
                                            <Input
                                                onChange={(e) => setEditProfile({
                                                    ...editProfile,
                                                    websiteUrl: e.target.value
                                                })}
                                                value={editProfile.websiteUrl || ''}
                                                minLength={2}
                                                maxLength={200}
                                                className={cn(
                                                    'mt-3 block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white',
                                                    'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                                                )}
                                            />
                                        </Field>
                                        <Field>
                                            <Label className="text-sm/6 font-medium text-white">Bio (optional)</Label>
                                            <Textarea onChange={e => setEditProfile({...editProfile, bio: e.target.value})}
                                                      value={editProfile.bio || ''}
                                                      minLength={2}
                                                      maxLength={200}
                                                      className={cn(
                                                          'mt-3 block w-full resize-none rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white',
                                                          'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                                                      )}
                                                      rows={3}
                                            />
                                        </Field>
                                    </div>
                                    {/*<!-- Modal footer */}
                                    <div
                                        className="flex items-center p-4 md:p-5 border-t rounded-b border-gray-600">
                                        <div className="flex items-center w-full justify-center">
                                            <Button
                                                onClick={handleUpdateProfile}
                                                disabled={
                                                    editProfile.displayName.length < 5 ||
                                                    editProfile.username.length < 5 ||
                                                    (editProfile.username !== user.username && error.validUsername === false)
                                                }
                                                className={`inline-flex duration-300 items-center gap-2 rounded-md py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none ${
                                                    editProfile.displayName.length < 5 ||
                                                    editProfile.username.length < 5 ||
                                                    (editProfile.username !== user.username && error.validUsername === false)
                                                        ? 'bg-gray-500 cursor-not-allowed'
                                                        : 'bg-gray-700 data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white'
                                                }`}
                                            >
                                                Save changes
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    )
                }

            </div>
            <FooterComponent/>
        </Fragment>
    );

}

export default AccountComponent;