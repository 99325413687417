import React, {Fragment, useCallback, useEffect, useState} from 'react';
import MenuComponent from "../../utils/menu/MenuComponent";
import {Button, Description, Field, Input, Label} from "@headlessui/react";
import {cn} from "../../../lib/utils";
import {API_BASE_URL} from "../../../config";
import {Link, useNavigate, useParams} from "react-router-dom";
import CategorySelect from "../../utils/CategorySelect";
import {FaTimes, FaUpload} from "react-icons/fa";
import {useCookies} from "../../../cookieContext";
import ConfirmationDialog from "../../utils/ConfirmationDialog";
import StructuredData from "../../utils/StructuredData";
import RichTextEditor from "../../utils/RichTextEditor";

const NewPlayGroundComponent = () => {
    const {slug, username} = useParams();
    const [image, setImage] = useState(null);
    const [imageUpload, setImageUpload] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [oldPlay, setOldPlay] = useState(null);
    const [slugAvailable, setSlugAvailable] = useState([]);
    const [isEmbed, setIsEmbed] = useState(true);
    const [content, setContent] = useState('');
    const [newPlay, setNewPlay] = useState({
        title: '',
        slug: '',
        link: '',
    });
    const [error, setError] = useState({
        title: false,
        link: false,
        image: false,
    });
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const {cookies} = useCookies();
    const navigate = useNavigate();

    useEffect(() => {
        if (cookies.token === null && cookies.username === null && cookies.username !== username) {
            navigate('/login');
        }
    })

    const handleDeletePlayground = async () => {
        try {
            await fetch(`${API_BASE_URL}/api/v1/playground/edit/${oldPlay.id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + cookies.token
                },
                body: JSON.stringify({
                    token: cookies.token
                })
            })
        } catch (error) {
            console.error('Error:', error);
        } finally {
            navigate(`/explore`);
            setIsDeleteOpen(false);
        }
    }

    const handleSubmit = async () => {
        try {
            let data;
            let playground;
            if (oldPlay !== null) {
                playground = {
                    title: newPlay.title,
                    slug: newPlay.slug,
                    link: newPlay.link,
                    description: content,
                    imageUrl: imageUrl,
                    username: username,
                    iframe: isEmbed
                }

                const response = await fetch(`${API_BASE_URL}/api/v1/playground/edit/${oldPlay.id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + cookies.token
                    },
                    body: JSON.stringify(playground)
                })
                data = await response.json();

            } else {
                playground = {
                    title: newPlay.title,
                    slug: newPlay.slug,
                    link: newPlay.link,
                    description: content,
                    imageUrl: imageUrl,
                    username: cookies.email,
                    iframe: isEmbed
                }

                const response = await fetch(`${API_BASE_URL}/api/v1/playground/edit`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + cookies.token
                    },
                    body: JSON.stringify(playground)
                })

                data = await response.json();

            }

            for (const selectedCategory of selectedCategories) {
                await fetch(API_BASE_URL + '/api/v1/playground-tag/edit/add', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + cookies.token
                    },
                    body: JSON.stringify({
                        playgroundId: data.id,
                        tagName: selectedCategory,
                        tagId: 0
                    })
                });
            }

            setNewPlay(
                {
                    title: '',
                    slug: '',
                    link: '',
                }
            )
            setContent('');
            setSelectedCategories([]);
            setImage(null);
            setImageUrl(null);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            navigate(`/explore`);
        }
    }

    const fetchPlay = useCallback(async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/api/v1/playground/get/${username}/${slug}`);
            const data = await response.json();
            setNewPlay(data);
            setContent(data.description);
            setIsEmbed(data.iframe);

            setImage(data.imageUrl);
            setImageUrl(data.imageUrl);

            setOldPlay(data);
        } catch (error) {
            navigate('/404');
        }
    }, [username, slug]);

    const checkSlugAvailability = useCallback(async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/api/v1/playground/get/slugs/${cookies.username}`)
            if (!response.ok) {
                throw new Error('Failed to check slug availability');
            }
            const data = await response.json();
            setSlugAvailable(data)

        } catch (error) {
            console.error('Error checking slug availability:', error);
            setSlugAvailable([]);
        }
    }, [username]);

    const fetchCategories = useCallback(async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/api/v1/playground-tag/get/link/${slug}`);
            const data = await response.json();
            const tagNames = data.map(item => item.tagName);
            setSelectedCategories(tagNames);
        } catch (error) {
            console.error('Error:', error);
        }
    }, [slug]);

    useEffect(() => {

    }, [slugAvailable]);


    useEffect(() => {
        if (slug && username) {
            const fetchData = async () => {
                await fetchPlay();
                await fetchCategories();
            };
            void fetchData();
        }
        void checkSlugAvailability();
    }, [slug, username, fetchPlay, fetchCategories, checkSlugAvailability]);

    const handleImageUpload = (e) => {
        if (image !== null) {
            handleImageDelete();
        }

        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('image', file);
            formData.append('email', cookies.email);

            fetch(`${API_BASE_URL}/api/v1/image/upload`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + cookies.token
                },
                body: formData
            })
                .then(response => response.json())
                .then(data => {
                    setImageUrl(API_BASE_URL + data.url); // Prepend API_BASE_URL to the relative URL
                    setImage(URL.createObjectURL(file));
                    setImageUpload(true);
                })
                .catch(error => error.image = true);
        }
    };

    const handleImageDelete = () => {
        if (imageUrl) {
            const filename = imageUrl.split('/').pop(); // Get the filename from the URL
            const email = cookies.email;

            fetch(`${API_BASE_URL}/api/v1/image/delete?email=${encodeURIComponent(email)}&filename=${encodeURIComponent(filename)}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + cookies.token
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to delete image');
                    }
                    return response.json();
                })
                .then(data => {
                    setImageUrl(null);
                    setImage(null);
                    const fileInput = document.getElementById('imageUpload');
                    if (fileInput) fileInput.value = '';
                })
                .catch(error => console.error('Error:', error));
        }
    };

    const structuredData = {
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        "name": "Share your amazing things with everyone.",
        "description": "Share your amazing things with everyone.",
        "author": {
            "@type": "Person",
            "name": cookies.username
        },
        "publisher": {
            "@type": "Organization",
            "name": "W404",
            "logo": {
                "@type": "ImageObject",
                "url": "https://w404.net/assets/images/logo1.png"
            }
        }
    };

    return (
        <Fragment>
            <MenuComponent/>
            <div className={"container mb-16"}>
                <StructuredData data={structuredData}
                                title={oldPlay !== null ? `Edit: ${oldPlay.title}` : "Share your amazing things with everyone."}
                                url={"https://w404.net/explore/new"}
                                description={"Share your amazing things with everyone."}/>
                <div className="my-6 w-3/5 m-auto">
                    <div className="flex items-center justify-between mb-3">
                        {
                            oldPlay !== null ? (
                                <Button onClick={() => setIsDeleteOpen(true)}
                                        className="w-fit inline-flex items-center gap-2 rounded-md border border-red-800 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner focus:outline-none data-[hover]:border-red-500 data-[open]:border-red-500 data-[focus]:outline-1 data-[focus]:outline-white transform duration-300">
                                    Delete Explore
                                </Button>
                            ) : (
                                <h1 className="text-2xl text-[#F4F4F5] text-center mb-4">Create New Explore</h1>
                            )
                        }

                        <button onClick={handleSubmit}
                                disabled={error.title || error.link || content.length < 0 || selectedCategories.length === 0}
                                className={"border border-[#6665F1] hover:bg-[#6665F1] transform duration-300 text-white py-2 px-5 rounded-lg"}>Share
                        </button>
                    </div>

                    <Field className={"mb-4"}>
                        <Label className="text-sm/6 font-medium text-white">Title</Label>
                        <Input
                            onChange={(e) => setNewPlay({...newPlay, title: e.target.value})}
                            value={newPlay !== null ? newPlay.title : ''}
                            className={cn(
                                'mt-2 block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white',
                                'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                            )}
                            onBlur={() => {
                                if (newPlay.title.length < 5 || newPlay.title.length > 222) {
                                    setError({...error, title: true});
                                } else {
                                    setError({...error, title: false});
                                }
                            }}
                        />
                        {
                            error.title ? (
                                <span
                                    className={"text-xs text-red-400"}>Please enter a title between 5 and 222 characters</span>
                            ) : null
                        }

                    </Field>
                    <Field className={"mb-4"}>
                        <Label className="text-sm/6 font-medium text-white">Edit link (optional)</Label>
                        <Description className="text-sm/6 text-white/50">
                            You can edit the link that will be used for this playground
                        </Description>
                        <Input
                            onChange={(e) => {
                                const value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
                                setNewPlay({...newPlay, slug: value});
                            }}
                            value={newPlay !== null ? newPlay.slug : ''}
                            className={cn(
                                'mt-2 block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white',
                                'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                            )}
                        />
                        {
                            newPlay.slug.length < 6 && newPlay.slug.length > 0 && (
                                <span className="text-xs text-red-400">Please enter a link with at least 5 characters</span>
                            )
                        }

                        {newPlay.slug.length > 5 && (
                            <span className="text-xs text-gray-400">
                            {slugAvailable.length === 0 ? '' :
                                slugAvailable.find(link => link.toString().toLowerCase() === newPlay.slug.toString().toLowerCase()) &&
                                (!oldPlay || oldPlay.slug !== newPlay.slug) ? (
                                    <span className="text-red-400">
                                        Sorry, this link is already used for{' '}
                                        <Link className="underline"
                                              to={`/${username}/s/${newPlay.slug}`}>this blog</Link>.
                                    </span>
                                ) : (
                                    <span className="text-green-400">This link is available for use.</span>
                                )
                            }
                        </span>
                        )}
                    </Field>
                    <Field className={"mb-4"}>
                        <Label className="text-sm/6 font-medium text-white mb-2 block">Tags</Label>
                        <CategorySelect
                            blogId={oldPlay !== null ? oldPlay.id : null}
                            selectedCategories={selectedCategories}
                            setSelectedCategories={setSelectedCategories}
                        />
                        {
                            selectedCategories.length === 0 ? (
                                <span className="text-xs text-red-400">Please select at least one tag!</span>
                            ) : null
                        }
                    </Field>
                    <Field className={"mb-4"}>
                        <Label className="text-sm/6 font-medium text-white">Link</Label>
                        <Description className="text-sm/6 text-white/50">
                            Share any links to websites you'd like.
                        </Description>
                        <Input
                            onChange={(e) => setNewPlay({...newPlay, link: e.target.value})}
                            value={true ? newPlay.link : ''}
                            className={cn(
                                'mt-2 block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white',
                                'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                            )}
                            onBlur={() => {
                                if (newPlay.link.length < 3 || newPlay.link.length > 222) {
                                    setError({...error, link: true});
                                } else {
                                    setError({...error, link: false});
                                }
                            }}
                        />
                        {
                            error.link ? (
                                <span className="text-xs text-red-400">
                                    Please enter a link between 3 and 222 characters!
                                </span>
                            ) : null
                        }

                    </Field>

                    <Field className={"mt-4 text-white"}>
                        <Label className="text-sm/6 font-medium text-white">Description</Label>
                        <Description className="text-sm/6 text-white/50 mb-2">
                            This will be shown under the product title.
                        </Description>
                        <RichTextEditor content={content} setContent={setContent}/>

                        {
                            error.content ? (
                                <span className="text-xs text-red-400">
                                Please enter a description!
                            </span>
                            ) : null
                        }
                    </Field>

                    <Field className={"mt-[-2rem]"}>
                        <div className="flex items-center">
                            <span className="text-sm/6 font-medium text-white mr-3">Embed Website:</span>
                            <label className="relative inline-flex items-center cursor-pointer z-0">
                                <input
                                    className="sr-only peer"
                                    type="checkbox"
                                    checked={isEmbed}
                                    onChange={(e) => setIsEmbed(e.target.checked)}

                                />
                                <div
                                    className="peer rounded-2xl outline-none duration-100 after:duration-500 w-[80px] h-7 bg-[#363636] peer-focus:outline-none after:content-['N'] after:absolute after:outline-none after:h-5 after:w-8 after:bg-white after:top-1 after:left-1 after:flex after:justify-center after:items-center  after:text-sky-800 after:font-bold peer-checked:after:translate-x-10 peer-checked:after:content-['Y'] peer-checked:after:border-white after:rounded-2xl after:px-2">
                                </div>
                            </label>
                        </div>
                    </Field>

                    <Field className={"mt-4"}>
                        <Label className="text-sm/6 font-medium text-white">Upload Image</Label>
                        <div className="flex items-center justify-center w-full">
                            <label htmlFor="imageUpload"
                                   className="flex flex-col items-center justify-center w-full h-64 border-2 border-[#6665F1] border-dashed rounded-lg cursor-pointer bg-gray-800 hover:bg-gray-700 transition-all duration-300">
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                    <FaUpload className="w-10 h-10 mb-3 text-[#e3eeff]"/>
                                    <p className="mb-2 text-sm text-gray-400"><span className="font-semibold">Click to upload</span> or
                                        drag and drop</p>
                                    <p className="text-xs text-gray-400">SVG, PNG, JPG or GIF (MAX. 1MB)</p>
                                </div>
                                <input
                                    id="imageUpload"
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageUpload}
                                    className="hidden"
                                />
                            </label>
                        </div>
                        {
                            error.image ? (
                                <div>
                                    <span className="text-xs text-red-400">
                                        Image too large! Please make sure the image is less than 1MB.
                                    </span>
                                    <span>
                                        or using this
                                        <Link to="https://imageresizer.com/" target="_blank">Image Resizer</Link>
                                    </span>
                                </div>
                            ) : null
                        }
                        {image && (
                            <div className={"flex justify-center items-center"}>
                                <div className="relative inline-block">
                                    <img src={image} alt="Uploaded preview" className="max-w-[300px] mt-2 rounded-md"/>
                                    <button
                                        onClick={handleImageDelete}
                                        className="absolute top-2 right-2 bg-red-500 rounded-full p-1 hover:bg-red-600 transition-colors duration-200"
                                    >
                                        <FaTimes size={16}/>
                                    </button>
                                </div>
                            </div>
                        )}
                    </Field>
                    <ConfirmationDialog
                        isOpen={isDeleteOpen}
                        onClose={() => setIsDeleteOpen(false)}
                        onConfirm={() => handleDeletePlayground()}
                        title={"Confirm Delete"}
                        message={"Are you sure you want to delete this playground?. This action cannot be undone."}
                    />
                </div>
            </div>
        </Fragment>
    );

}

export default NewPlayGroundComponent;