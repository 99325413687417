import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {FaFacebook, FaTelegram} from "react-icons/fa";
import {FaXTwitter} from "react-icons/fa6";

class FooterComponent extends Component {
    render() {
        return (
            <footer className="w-full shadow mt-5">
                <div className="w-full max-w-screen-xl mx-auto px-4">
                    <div className="sm:flex sm:items-center sm:justify-between">
                        <Link to="/"
                              className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
                            <img src="/assets/images/logo2.png" className="h-8" alt="W404 Logo"/>
                        </Link>
                        <ul className="flex flex-wrap items-center mb-6 text-sm font-medium sm:mb-0 text-[#A1A1AA]">
                            <li>
                                <Link to="/" className="hover:underline me-4 md:me-6">About</Link>
                            </li>
                            <li>
                                <Link to="/explore" className="hover:underline me-4 md:me-6">Explore</Link>
                            </li>
                            <li>
                                <Link to="/blogs" className="hover:underline me-4 md:me-6">Blogs</Link>
                            </li>
                            <li>
                                <Link to="/privacy" className="hover:underline">Privacy Policy</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <hr className="my-2 border-gray-700 lg:my-4"/>
                <div className="w-full max-w-screen-xl mx-auto pb-4 px-4">
                    <div className="flex justify-between">
                        <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2024
                            <Link to="/" className="hover:underline ml-1">W404™</Link>
                        </span>
                        <div className="flex mt-4 sm:justify-center sm:mt-0 gap-2">
                            <a href="https://www.facebook.com/w404.net" target="_blank" rel="noopener noreferrer"
                               className="text-[#A1A1AA] hover:text-[#F4F4F5]">
                                <FaFacebook/>
                                <span className="sr-only">Facebook page</span>
                            </a>
                            <a href="https://x.com/W404_NET" target="_blank" rel="noopener noreferrer"
                               className="text-[#A1A1AA] hover:text-[#F4F4F5]">
                                <FaXTwitter/>
                                <span className="sr-only">X</span>
                            </a>
                            <a href="https://t.me/MinhxThanh" target="_blank" rel="noopener noreferrer"
                               className="text-[#A1A1AA] hover:text-[#F4F4F5]">
                                <FaTelegram/>
                                <span className="sr-only">Telegram</span>
                            </a>
                        </div>
                    </div>
                </div>
            </footer>


        );
    }
}

export default FooterComponent;