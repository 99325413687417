import React from 'react';
import {Link} from "react-router-dom";

const PolicyCard = ({ icon, title, description }) => (
    <div className="flex flex-col space-y-4 bg-[#1E1E1E] p-6 rounded-xl transition-all duration-300 hover:bg-[#2A2A2A]">
        {icon}
        <h2 className="text-2xl font-semibold text-white">{title}</h2>
        <p className="text-gray-300">{description}</p>
    </div>
);

const Section = ({ title, children }) => (
    <div className="bg-[#1E1E1E] p-6 rounded-xl mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">{title}</h2>
        {children}
    </div>
);

const TermsAndPrivacyPolicy = () => {
    const policyCards = [
        {
            icon: (
                <svg className="h-10 w-10 text-purple-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                </svg>
            ),
            title: "Data Protection",
            description: "We do not sell your personal data or account information. Your privacy is our top priority, and we implement stringent measures to safeguard your data.",
        },
        {
            icon: (
                <svg className="h-10 w-10 text-purple-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01" />
                </svg>
            ),
            title: "Secure Storage",
            description: "All your information is securely stored on our servers using industry-standard encryption protocols. We regularly update our security measures to ensure data integrity and protection.",
        },
        {
            icon: (
                <svg className="h-10 w-10 text-purple-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                </svg>
            ),
            title: "Freedom of Expression",
            description: "We support free expression. You can write and share any content, including NSFW material, as long as it complies with applicable laws and our community guidelines.",
        },
        {
            icon: (
                <svg className="h-10 w-10 text-purple-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4" />
                </svg>
            ),
            title: "User Responsibility",
            description: "You are responsible for the content you share. We encourage respectful and lawful use of our platform. Violation of our terms may result in account suspension or termination.",
        },
    ];

    return (
        <main className="min-h-screen flex items-center justify-center p-4 bg-[#121212]">
            <div className="max-w-4xl mx-auto">
                <header className="text-center mb-16">
                    <h1 className="text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600 mb-6 tracking-tight">
                        Terms & Privacy Policy
                    </h1>
                    <p className="text-xl text-gray-300 max-w-2xl mx-auto leading-relaxed">
                        Our commitment to protecting your privacy and ensuring a safe, open platform for sharing.
                    </p>
                </header>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
                    {policyCards.map((card, index) => (
                        <PolicyCard key={index} {...card} />
                    ))}
                </div>

                <Section title="Acceptance of this Policy">
                    <p className="text-gray-300">
                        You acknowledge that you have read this Policy and agree to all its terms and conditions. By using the Website, you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to use or access the Website.
                    </p>
                </Section>

                <div className="text-center">
                    <h2 className="text-2xl font-semibold text-white mb-4">Contacting Us</h2>
                    <p className="text-gray-300">
                        If you have any questions about this Policy, please contact us by email at:
                        <a href="mailto:support@abc.com" className="text-purple-400 hover:text-purple-300 transition-colors duration-300 ml-1">
                            support@w404.net
                        </a>
                    </p>
                    <p className="text-gray-300">
                        Check page privacy for more information. <Link to="/privacy" className="text-purple-400 hover:text-purple-300 transition-colors duration-300">Privacy</Link>
                    </p>
                </div>
            </div>
        </main>
    );
};

export default TermsAndPrivacyPolicy;