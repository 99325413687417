import Marquee from "../../ui/marquee";
import { cn } from "../../lib/utils";
import ImageComponent from "./ImageComponent";

const reviews = [
    {
        name: "Jack",
        username: "@jack",
        body: "W404 World is incredible! I can create and share anything without limits. The freedom of expression here is unmatched.",
        img: "https://images.unsplash.com/photo-1678286742832-26543bb49959?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8fDA%3D",
    },
    {
        name: "Jill",
        username: "@jill",
        body: "I'm impressed by W404's commitment to data protection. It's refreshing to find a platform that prioritizes user privacy.",
        img: "https://images.unsplash.com/photo-1532074205216-d0e1f4b87368?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
    {
        name: "John",
        username: "@john",
        body: "The ability to share NSFW content responsibly is a game-changer. W404 truly understands the balance between freedom and responsibility.",
        img: "https://images.unsplash.com/photo-1702482527875-e16d07f0d91b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8fDA%3D",
    },
    {
        name: "Jane",
        username: "@jane",
        body: "I appreciate how W404 World is transparent about their policies. It's great to see a platform that keeps users informed about changes.",
        img: "https://plus.unsplash.com/premium_photo-1678197937465-bdbc4ed95815?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8cGVyc29ufGVufDB8fDB8fHww",
    },
    {
        name: "Jenny",
        username: "@jenny",
        body: "The secure storage and encryption of data on W404 give me peace of mind. I feel safe sharing my creations here.",
        img: "https://images.unsplash.com/photo-1609042759065-d8dad38f5a9f?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8cGVyc29ufGVufDB8fDB8fHww",
    },
    {
        name: "James",
        username: "@james",
        body: "W404's user responsibility policy creates a respectful community. It's the perfect place to express ideas and connect with like-minded creators.",
        img: "https://images.unsplash.com/photo-1534308143481-c55f00be8bd7?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
    {
        name: "Emma",
        username: "@emma",
        body: "The option to request access to my personal data or ask for its deletion shows W404's commitment to user control. It's empowering!",
        img: "https://images.unsplash.com/photo-1463453091185-61582044d556?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
    {
        name: "Michael",
        username: "@michael",
        body: "I love how W404 World complies with data protection laws while still maintaining an open platform. It's the best of both worlds!",
        img: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTl8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
    }
];


const firstRow = reviews.slice(0, reviews.length / 2);
const secondRow = reviews.slice(reviews.length / 2);

const ReviewCard = ({ img, name, username, body }) => {
    return (
        <figure
            className={cn(
                "relative w-64 cursor-pointer overflow-hidden rounded-xl border p-4",
                // dark styles
                "border-gray-50/[.1] bg-gray-50/[.10] hover:bg-gray-50/[.15]",
            )}
        >
            <div className="flex flex-row items-center gap-2">
                <ImageComponent className={"w-10 h-10"} image={img} alt={name} />
                <div className="flex flex-col">
                    <figcaption className="text-sm font-medium text-white">
                        {name}
                    </figcaption>
                    <p className="text-xs font-medium text-white/40">{username}</p>
                </div>
            </div>
            <blockquote className="mt-2 text-sm text-white">{body}</blockquote>
        </figure>
    );
};

export function MarqueeReviewCard() {
    return (
        <div className="relative flex h-svh w-full flex-col items-center justify-center overflow-hidden">
            <Marquee pauseOnHover className="[--duration:20s]">
                {firstRow.map((review) => (
                    <ReviewCard key={review.username} {...review} />
                ))}
            </Marquee>
            <Marquee reverse pauseOnHover className="[--duration:20s]">
                {secondRow.map((review) => (
                    <ReviewCard key={review.username} {...review} />
                ))}
            </Marquee>
            <div className="pointer-events-none absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-[#121212] from-background"></div>
            <div className="pointer-events-none absolute inset-y-0 right-0 w-1/3 bg-gradient-to-l from-[#121212] from-background"></div>
        </div>
    );
}