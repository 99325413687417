import React, {useEffect, useState, useCallback, Fragment} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import MenuComponent from '../utils/menu/MenuComponent';
import HeaderPlayGround from './item/HeaderPlayGround';
import SearchPlayGround from './item/SearchPlayGround';
import './PLaygroundStyle.css';
import ItemPlayground from './item/ItemPlayground';
import {BadgeCategories} from '../utils/BadgeCategories';
import FooterComponent from '../utils/footer/FooterComponent';
import {API_BASE_URL} from '../../config';
import {CircularPagination} from '../utils/pagination';
import DropdownSortBy from './item/DropdownSortBy';
import Spinner2 from "../utils/sipnner/Spinner2";
import {Button} from "@headlessui/react";
import {BsBookmarks} from "react-icons/bs";
import {useCookies} from "../../cookieContext";
import StructuredData from "../utils/StructuredData";

const PlaygroundComponent = () => {
    const {tagName} = useParams();
    const navigate = useNavigate();
    const {cookies} = useCookies();

    const [listTag, setListTag] = useState([]);
    const [playgroundItems, setPlaygroundItems] = useState([]);

    const [page, setPage] = useState(1);
    const [size] = useState(15); // Changed from 1 to 10 for better pagination
    const [totalPages, setTotalPages] = useState(0);
    const [sortBy, setSortBy] = useState('trending');

    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(true);
    const [loadSaved, setLoadSaved] = useState(false);

    const usernameLocal = cookies.username;

    const fetchData = useCallback(async (url) => {
        try {
            setLoading(true);
            const response = await fetch(url);
            const data = await response.json();
            if (data._embedded?.playgroundPostDTOList) {
                setPlaygroundItems(data._embedded.playgroundPostDTOList);
                setTotalPages(data.page.totalPages);
            } else {
                setPlaygroundItems([]);
                setTotalPages(0);
            }
        } catch (e) {
            setLoading(false);
            console.error('Error fetching data:', e);
        }
    }, []);

    const fetchTags = useCallback(async () => {
        try {
            setLoading(true);
            const response = await fetch(`${API_BASE_URL}/api/v1/playground-tag/get/all`);
            const data = await response.json();
            setListTag(data);
        } catch (e) {
            console.error('Error fetching data:', e);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchTags();

        let url;
        if (tagName) {
            url = `${API_BASE_URL}/api/v1/playground/get/tag/${tagName}?page=${page - 1}&size=${size}`;
        } else if (searchValue) {
            url = `${API_BASE_URL}/api/v1/playground/get/all/${searchValue}?page=${page - 1}&size=${size}`;
        } else if (loadSaved && usernameLocal) {
            url = `${API_BASE_URL}/api/v1/playground/get/saved/${usernameLocal}?page=${page - 1}&size=${size}`;
        } else {
            url = `${API_BASE_URL}/api/v1/playground/get/by/${sortBy}?page=${page - 1}&size=${size}`;
        }

        fetchData(url);
    }, [loadSaved, usernameLocal, page, size, sortBy, tagName, searchValue, fetchData, fetchTags]);

    const handleSearch = (value) => {
        setLoadSaved(false);
        setSearchValue(value);
        setPage(1);
        if (tagName) {
            navigate('/explore'); // Navigate to base playground route
        }
    };

    const handleSortBy = (value) => {
        setLoadSaved(false);
        setSortBy(value);
        setSearchValue('');
        setPage(1);
        if (tagName || searchValue) {
            setSearchValue('');
            navigate('/explore'); // Navigate to base playground route
        }
    }

    const handleLoadSaved = () => {
        setLoadSaved(true)
        setSearchValue('');
        setPage(1);
        if (tagName || searchValue) {
            setSearchValue('');
            navigate('/explore'); // Navigate to base playground route
        }
    }

    const structuredData = {
        "@context": "https://schema.org",
        "@type": "CollectionPage",
        "mainEntity": {
            "@type": "ItemList",
            "itemListElement": playgroundItems.slice(0, 10).map((item, index) => ({
                "@type": "ListItem",
                "position": index + 1,
                "item": {
                    "@type": "BlogPosting",
                    "headline": item.title,
                },
                "url": `https://w404.net/${item.username}/e/${item.slug}`
            }))
        },
        "url": "https://w404.net/explore",
        "name": "W404 Explores The Amazing Things",
        "description": "Dive into a fascinating world where you can discover incredible tools, cutting-edge artificial intelligence, and a plethora of other exciting innovations that are just waiting to be explored and shared with friends, colleagues, and communities around the globe.",
        "publisher": {
            "@type": "Organization",
            "name": "W404",
            "logo": {
                "@type": "ImageObject",
                "url": "https://w404.net/assets/images/logo1.png"
            }
        }
    };

    return (
        <Fragment>
            <StructuredData data={structuredData} title={"W404 Explores The Amazing Things"} url={"https://w404.net/explore"}
                            description={"Dive into a fascinating world where you can discover incredible tools, cutting-edge artificial intelligence, and a plethora of other exciting innovations that are just waiting to be explored and shared with friends, colleagues, and communities around the globe."}/>
            <MenuComponent/>
            <div className="container">
                <HeaderPlayGround/>
                <SearchPlayGround searchValue={handleSearch}/>

                <div className="flex justify-center flex-wrap gap-2">
                    {listTag.map((tag, index) => (
                        <BadgeCategories key={index} TagSelected={tagName}>
                            {tag}
                        </BadgeCategories>
                    ))}
                </div>

                <div className="flex my-5 mt-3 items-center justify-between">
                    <DropdownSortBy setSortBy={handleSortBy}/>
                    {
                        usernameLocal && (
                            <Button onClick={handleLoadSaved}
                                    className="sm:text-[12px] px-5 h-[2.3rem] text-[#A1A1AA] border border-[#6665F190] rounded-xl hover:border-[#6665F1] hover:text-[#F4F4F5] transform duration-300">
                                <BsBookmarks className={"blogs-content-left-btn-icon inline mr-2"}/>
                                <span className={"text-[13px]"}>Saved</span>
                            </Button>
                        )
                    }
                </div>

                {
                    loading ? <Spinner2/> :
                        (
                            <Fragment>
                                <div className="flex px-10 flex-wrap gap-4">
                                    {playgroundItems.map((item, index) => (
                                        <ItemPlayground key={index} item={item}/>
                                    ))}
                                </div>

                                {
                                    totalPages === 0 && (
                                        <div className="flex justify-center mt-5 h-[40svh] ">
                                            <p className="text-white">No results found</p>
                                        </div>
                                    )
                                }

                                {
                                    totalPages > 0 && (
                                        <div className="flex justify-center mt-5">
                                            <CircularPagination page={page} setPage={setPage} totalPages={totalPages} />
                                        </div>
                                    )
                                }
                            </Fragment>
                        )
                }

            </div>
            <FooterComponent/>
        </Fragment>
    );
};

export default PlaygroundComponent;