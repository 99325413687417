import React from 'react';

const ImageComponent = ({className, image, imgClass, alt}) => {
    return (
        <div
            className={`${className} rounded-full border`} style={{padding: '1px'}}>
            <img
                src={`${image}`}
                alt={alt}
                className={`rounded-full w-full h-full ${imgClass}`}
                crossOrigin="anonymous"
            />
        </div>
    );
};

export default ImageComponent;
