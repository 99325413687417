import React, {Fragment, useEffect, useState} from 'react';
import MenuComponent from "../utils/menu/MenuComponent";
import {useCookies} from "../../cookieContext";
import {useNavigate, useParams} from "react-router-dom";
import {API_BASE_URL} from "../../config";
import Spinner2 from "../utils/sipnner/Spinner2";
import StructuredData from "../utils/StructuredData";

const EmailVerificationComponent = () => {
    const {codeVerify} = useParams();
    const navigation = useNavigate();
    const {cookies, setCookie, removeCookie} = useCookies()
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (cookies?.verified === 'true' || codeVerify === null) {
            navigation('/')
        }
    })

    const handleVerify = async () => {
        if (cookies.codeVerify !== codeVerify) {
            removeCookie('codeVerify')
            navigation('/')
        }
        try {
            setLoading(true);
            const response = await fetch(`${API_BASE_URL}/api/v1/user/edit/update-verify`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + cookies.token
                },
                body: JSON.stringify({
                    username: cookies.username,
                    token: cookies.token,
                })
            });
            if (!response.ok) {
                throw new Error('Failed to verify email');
            }
            removeCookie('codeVerify');
            setCookie('verified', true)
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false);
            navigation('/')
        }
    };

    const structuredData = {
        "@context": "https://schema.org",
        "@type": "ProfilePage",
        "url": `https://w404.net/verify/${codeVerify}`,
        "name": `Verify Email`,
        "description": `Click the button below to verify your email address`,
    };

    return (
        <Fragment>
            <StructuredData data={structuredData} title={"Verify Email"} url={`https://w404.net/verify/${codeVerify}`}
                            description={"Click the button below to verify your email address"}/>
            <MenuComponent/>
            <div className="min-h-[80svh] flex items-center justify-center bg-[#121212] p-4">
                <div className="max-w-md w-full space-y-8 bg-[#1E1E1E] p-8 rounded-xl shadow-lg">
                    {
                        loading ? ( <Spinner2 /> ) : (
                            <Fragment>
                                <div className="text-center">
                                    <h2 className="mt-6 text-3xl font-extrabold text-white">
                                        Verify Your Email
                                    </h2>
                                    <p className="mt-2 text-sm text-gray-400">
                                        Click the button below to verify your email address. Please ensure you open this
                                        link in the same browser where you are currently logged in.
                                    </p>
                                </div>
                                <div className="mt-8 space-y-6">
                                    <button
                                        onClick={handleVerify}
                                        className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                                            'bg-purple-600 hover:bg-purple-700'
                                        } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition-colors duration-300`}
                                    >
                                        Verify Email
                                    </button>
                                </div>
                            </Fragment>
                        )
                    }
                </div>
            </div>
        </Fragment>
    );
};

export default EmailVerificationComponent;