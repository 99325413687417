import React, { createContext, useState, useContext, useCallback } from 'react';
import Cookies from 'js-cookie';

const CookieContext = createContext();

export const useCookies = () => useContext(CookieContext);

export const CookieProvider = ({ children }) => {
    const [cookies, setCookies] = useState(() => {
        // Initialize state with current cookies
        return {
            username: Cookies.get('username') || null,
            token: Cookies.get('token') || null,
            verified: Cookies.get('verified') || null,
            profileImageUrl: Cookies.get('profileImageUrl') || null,
            email: Cookies.get('email') || null,
            codeVerify: Cookies.get('codeVerify') || null,
            codePassword: Cookies.get('codePassword') || null,
        };
    });

    const setCookie = useCallback((name, value, options = {}) => {
        // Calculate the date 90 days from now
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 90);

        const defaultOptions = {
            expires: expirationDate,
            path: '/',
            secure: true,
            // domain: '.w404.net',
            sameSite: 'lax',
            ...options
        };


        Cookies.set(name, value, defaultOptions);
        setCookies(prevCookies => ({ ...prevCookies, [name]: value }));
    }, []);

    const removeCookie = useCallback((name) => {
        Cookies.remove(name, { path: '/' });
        setCookies(prevCookies => {
            const newCookies = { ...prevCookies };
            delete newCookies[name];
            return newCookies;
        });
    }, []);

    return (
        <CookieContext.Provider value={{ cookies, setCookie, removeCookie }}>
            {children}
        </CookieContext.Provider>
    );
};