    import React, {Component, Fragment} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import BlogsComponent from "./components/blog/BlogsComponent";
import PlaygroundComponent from "./components/playground/PlaygroundComponent";
import PlaygroundDetailComponent from "./components/playground/detail/PlaygroundDetailComponent";
import BlogDetailComponent from "./components/blog/detail/BlogDetailComponent";
import NotFoundComponent from "./components/utils/NotFoundComponent";
import Spinner from "./components/utils/sipnner/Spinner";
import AccountComponent from "./components/account/AccountComponent";
import NewPlayGroundComponent from "./components/playground/create/NewPlayGroundComponent";
import SelectHowCreateNewBlogComponent from "./components/blog/create/SelectHowCreateNewBlogComponent";
import LoginComponent from "./components/login/LoginComponent";
import SimpleBlogComponent from "./components/blog/create/simple/SimpleBlogComponent";
import {CookieProvider} from "./cookieContext";
import EmailVerificationComponent from "./components/verify/EmailVerificationComponent";
import PrivacyComponent from "./components/utils/PrivacyComponent";
import ChangePasswordComponent from "./components/account/ChangePasswordComponent";
import RequestChangePassword from "./components/login/RequestChangePassword";

const root = ReactDOM.createRoot(document.getElementById('root'));

class Root extends Component {
    render() {
        const {loading} = this.props;
        return (
            <Fragment>
                {loading ? <Spinner></Spinner> : (
                    <Routes>
                        <Route path="/" element={<App/>}/>
                        {/*Explore*/}
                        <Route path={"/explore"} element={<PlaygroundComponent/>}/>
                        <Route path={"/:username/e/:slug"} element={<PlaygroundDetailComponent/>}/>
                        <Route path={"/explore/:tagName"} element={<PlaygroundComponent/>}/>
                        <Route path={"/explore/new"} element={<NewPlayGroundComponent/>}/>
                        <Route path={"/:username/edit/e/:slug"} element={<NewPlayGroundComponent/>}/>

                        {/*Blog*/}
                        <Route path={"/blogs"} element={<BlogsComponent/>}/>
                        <Route path={"/blogs/:sortByParams"} element={<BlogsComponent/>}/>
                        <Route path={"/:username/b/:slug"} element={<BlogDetailComponent/>}/>
                        <Route path={"/blog/new"} element={<SimpleBlogComponent/>}/>
                        <Route path={"/blog/types"} element={<SelectHowCreateNewBlogComponent/>}/>
                        {/*edit Blog*/}
                        <Route path={"/:username/edit/b/:slug"} element={<SimpleBlogComponent/>}/>
                        {/*Account Settings*/}
                        <Route path={"/a/:username"} element={<AccountComponent/>}/>
                        {/*Login page*/}
                        <Route path={"/login"} element={<LoginComponent/>}/>
                        <Route path={"/change-password/:codeVerify"} element={<ChangePasswordComponent/>}/>
                        <Route path={"/forgot-password"} element={<RequestChangePassword/>}/>

                        {/* This is the catch-all route */}
                        <Route path={"/404"} element={<NotFoundComponent/>}/>
                        <Route path={"*"} element={<NotFoundComponent/>}/>
                        <Route path={"/verify/:codeVerify"} element={<EmailVerificationComponent/>}/>
                        <Route path={"/privacy"} element={<PrivacyComponent/>}/>
                    </Routes>
                )}
            </Fragment>
        );
    }
}

root.render(
    <BrowserRouter>
        <CookieProvider>
            <Root/>
        </CookieProvider>
    </BrowserRouter>
);

reportWebVitals();
