import { cn } from "../lib/utils";
import React from "react";

export const RetroGrid = ({ className }) => {
    return (
        <div
            className={cn(
                "pointer-events-none absolute h-full w-full overflow-hidden opacity-50 [perspective:200px]",
                className,
            )}
        >
            {/* Grid */}
            <div className="absolute inset-0 [transform:rotateX(35deg)]">
                <div
                    className={cn(
                        "animate-grid",

                        "[background-repeat:repeat] [background-size:60px_60px] [height:300vh] [inset:0%_0px] [margin-left:-50%] [transform-origin:100%_0_0] [width:600vw]",

                        // Light Styles
                        "[background-image:linear-gradient(to_right,rgba(244,244,245,0.3)_1px,transparent_0),linear-gradient(to_bottom,rgba(161,161,170,0.3)_1px,transparent_0)]"
                    )}
                />
            </div>
        </div>
    );
}